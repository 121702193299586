import { CustomInput } from "UI/components";
import { Label } from "./experience-form.styled";
import { Buttons } from "UI/components/shared/custom-input/custom-input.styled";
import { Experience } from "repositories/experience-repository/experience.repository";

import React from "react";
import { Box } from "@mui/material";
import { CustomInputAutoComplete } from "../../components/shared/custom-input-auto-complete/custom-input-auto-complete.component";
import { programmingLanguages, subjects } from "data";

interface StepProps {
    experience: Experience,
    handleSubmitExperienceData: any,
    errors: any,
    actionsSupported?: any,
    otherValues?: any,
    setOtherValues?: any
}

export const FirstStep: React.FC<StepProps> = ({ errors, experience, handleSubmitExperienceData }) => {
    return (
        <>

            <CustomInput
                inputType="textarea"
                id="timeToApply"
                height="12px"
                label="Tempo de aplicação em Min"
                withouteffectlabel={true}
                value={experience.timeToApply}
                onChange={(e) => handleSubmitExperienceData({ field: 'timeToApply', value: e.target.value })}
                placeholder="15-45"
                flex="1 0 40%"
                errorMessage={errors["timeToApply"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textarea"
                id="participants"
                height="12px"
                label="Qts. participantes"
                withouteffectlabel={true}
                value={experience.participants}
                onChange={(e) => handleSubmitExperienceData({ field: 'participants', value: e.target.value })}
                placeholder="20-45"
                flex="1 0 40%"
                errorMessage={errors["participants"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textarea"
                id="contents"
                height="56px"
                label="Conteudos aplicados"
                withouteffectlabel={true}
                value={experience.contents}
                onChange={(e) => handleSubmitExperienceData({ field: 'contents', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["contents"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textArea"
                id="materials"
                height="56px"
                label="Materiais ou ferramentas usadas"
                withouteffectlabel={true}
                value={experience.materials}
                onChange={(e) => handleSubmitExperienceData({ field: 'materials', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
            />

            <CustomInput
                inputType="textarea"
                id="experienceReport"
                height="56px"
                label="Conte-nos sua experiência na adoção desta metodologia"
                withouteffectlabel={true}
                value={experience.experienceReport}
                onChange={(e) => handleSubmitExperienceData({ field: 'experienceReport', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["experienceReport"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textArea"
                id="adaptedPlanDescription"
                height="56px"
                label="Conte se fez alguma(s) adaptação(ões) para a adoção da metodologia."
                withouteffectlabel={true}
                value={experience.adaptedPlanDescription}
                onChange={(e) => handleSubmitExperienceData({ field: 'adaptedPlanDescription', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
            />
        </>
    )
}

export const SecondStep: React.FC<StepProps> = ({ errors, experience, handleSubmitExperienceData, otherValues, setOtherValues }) => {

    const handleOtherValues = (field: string, value: string) => {
        setOtherValues({ ...otherValues, [field]: value });
    }
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isMobile = windowWidth < 540;

    return (
        <Box width='100%' display='flex' flexDirection={!isMobile ? 'row' : 'column'} justifyContent='space-between' >
            <div>
                <CustomInputAutoComplete
                    handleValues={(val) => handleSubmitExperienceData({ field: 'disciplines', value: val })}
                    description='Introdução a programação'
                    optionsValues={subjects}
                    title="Disciplinas aplicadas"
                    acceptsMultipleChoices
                />

                {experience.disciplines.includes('others') && <CustomInput
                    inputType="input"
                    id='others'
                    placeholder="Digite a disciplina que foi aplicada"
                    withouteffectlabel={true}
                    value={otherValues.disciplines}
                    height="30px"
                    onChange={(e) => handleOtherValues('disciplines', e.target.value)}
                />}
            </div>

            <div>
                <CustomInputAutoComplete
                    handleValues={(val) => handleSubmitExperienceData({ field: 'languages', value: val })}
                    description='java'
                    optionsValues={programmingLanguages}
                    title="Linguagens de programação que foram utilizadas"
                    acceptsMultipleChoices
                />
                {experience.languages.includes('others') && <CustomInput
                    inputType="input"
                    id='others'
                    placeholder="Digite a linguagem que foi usada"
                    withouteffectlabel={true}
                    value={otherValues.languages}
                    height="30px"
                    onChange={(e) => handleOtherValues('languages', e.target.value)}
                />}
            </div>
        </Box>
    )
}

export const ThirdyStep: React.FC<StepProps> = ({ errors, experience, handleSubmitExperienceData }) => {
    return (
        <>
            <CustomInput
                inputType="textArea"
                id="advantages"
                height="56px"
                label="Quais as vantagens de ter aplicado esta metogologia?"
                withouteffectlabel={true}
                value={experience.advantages}
                onChange={(e) => handleSubmitExperienceData({ field: 'advantages', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["advantages"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textArea"
                id="challenges"
                height="56px"
                label="Quais os desafios de aplicar esta metogologia?"
                withouteffectlabel={true}
                value={experience.challenges}
                onChange={(e) => handleSubmitExperienceData({ field: 'challenges', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["challenges"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textArea"
                id="evaluate"
                height="56px"
                label="Como avaliou o aprendizado?"
                withouteffectlabel={true}
                value={experience.evaluate}
                onChange={(e) => handleSubmitExperienceData({ field: 'evaluate', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["evaluate"] ? "Campo obrigatório" : ""}
                required
            />

            <CustomInput
                inputType="textArea"
                id="results"
                height="56px"
                label="Resultados alcançados"
                withouteffectlabel={true}
                value={experience.results}
                onChange={(e) => handleSubmitExperienceData({ field: 'results', value: e.target.value })}
                placeholder=""
                flex="1 0 40%"
                errorMessage={errors["results"] ? "Campo obrigatório" : ""}
                required
            />
        </>
    )
}

export const FourthStep: React.FC<StepProps> = ({ actionsSupported, errors, experience, handleSubmitExperienceData }) => {
    const { publications } = experience;

    return (
        <>
            <Label> Caso tenha publicado algo a respeito desta metodologia, deixa aqui o nome ou o artigo.</Label>
            {publications.map((experience, index) => (
                <>
                    <CustomInput
                        inputType="input"
                        key={`experienceArticle${index}`}
                        id={`experienceArticle${index}`}
                        height="30px"
                        label={`Nome do artigo`}
                        type="text"
                        placeholder="Applying PBL in Teaching Programming: na Experience Report "
                        withouteffectlabel={true}
                        value={experience?.article}
                        onChange={(event) =>
                            handleSubmitExperienceData({ field: "publications", subField: "article", value: event.target.value, index, action: actionsSupported.change })
                        }
                        flex="1 0 100%"
                    />
                    <CustomInput
                        inputType="textarea"
                        id={`experienceLink${index}`}
                        height="30px"
                        label={`Link do artigo`}
                        withouteffectlabel={true}
                        value={experience?.link}
                        placeholder="https://ieeexplore.ieee.org/abstract/document/8658978"
                        onChange={(event) =>
                            handleSubmitExperienceData({ field: "publications", subField: "link", value: event.target.value, index, action: actionsSupported.change })
                        }
                        flex="1 0 100%"
                    />
                    <Buttons>
                        {index !== 0 && index === publications?.length - 1 && (
                            <h4 onClick={() => handleSubmitExperienceData({ field: "publications", subField: "link", action: actionsSupported.remove })}>
                                Remover experiencia
                            </h4>
                        )}
                        {index === publications.length - 1 && (
                            <p onClick={() => handleSubmitExperienceData({ field: "publications", action: actionsSupported.add })}>Adicionar experiencia</p>
                        )}
                    </Buttons>
                </>
            ))}

            <CustomInput
                inputType="textArea"
                id="considerations"
                height="56px"
                label="Deixe suas considerações sobre a metodologias"
                withouteffectlabel={true}
                value={experience.considerations}
                onChange={(e) => handleSubmitExperienceData({ field: 'considerations', value: e.target.value })}
                placeholder=""
                flex="1 0 60%"
                errorMessage={errors["considerations"] ? "Campo obrigatório" : ""}
                required
            />
        </>
    )
}

export default { FirstStep, SecondStep, ThirdyStep, FourthStep };