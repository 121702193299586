import { Step, StepHead } from "UI/components/shared/steps-cards/steps-cards.styled";
import { useState } from "react";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { styled } from "styled-components";

interface AboutProps { data: any }

type AboutData = {
    subjects: boolean,
    contents: boolean,
    programmingLanguages: boolean,
    principles: boolean,
    observedMetrics: boolean,
    advantages: boolean,
    challenges: boolean,
    considerations: boolean,
    references: boolean,
    experiences: boolean
};

const initialState: AboutData = {
    subjects: false,
    contents: false,
    programmingLanguages: false,
    principles: false,
    observedMetrics: false,
    advantages: false,
    challenges: false,
    considerations: false,
    experiences: false,
    references: false
};

const About: React.FC<AboutProps> = ({ data }) => {
    const [dataState, setDataState] = useState<AboutData>(initialState);

    const handleFieldClick = (field: keyof AboutData) => {
        setDataState((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <>
            <Step>
                <StepHead>
                    <h3>Princípios da metodologia</h3>
                    <div onClick={() => handleFieldClick('principles')}>
                        {dataState.principles ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.principles && <h4>{data.principles}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Disciplinas onde foi lecionada</h3>
                    <div onClick={() => handleFieldClick('subjects')}>
                        {dataState.subjects ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.subjects && <h4>{data.subjects}</h4>}
            </Step>
           {data.contents.length > 0 && <Step>
                <StepHead>
                    <h3>Conteúdos lecionados</h3>
                    <div onClick={() => handleFieldClick('contents')}>
                        {dataState.contents ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.contents && <h4>{data.contents}</h4>}
            </Step>}
            <Step>
                <StepHead>
                    <h3>Linguagens de programação utilizadas</h3>
                    <div onClick={() => handleFieldClick('programmingLanguages')}>
                        {dataState.programmingLanguages ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.programmingLanguages && <h4>{data.programmingLanguages}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Métricas observadas</h3>
                    <div onClick={() => handleFieldClick('observedMetrics')}>
                        {dataState.observedMetrics ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.observedMetrics && <h4>{data.observedMetrics}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Vantagens da adoção da metodologia</h3>
                    <div onClick={() => handleFieldClick('advantages')}>
                        {dataState.advantages ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.advantages && <h4>{data.advantages}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Desafios da adoção  da metodologia?</h3>
                    <div onClick={() => handleFieldClick('challenges')}>
                        {dataState.challenges ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.challenges && <h4>{data.challenges}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Considerações</h3>
                    <div onClick={() => handleFieldClick('considerations')}>
                        {dataState.considerations ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.considerations && <h4>{data.considerations}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Experiências de adoção  da metodologia em sala de aula</h3>
                    <div onClick={() => handleFieldClick('experiences')}>
                        {dataState.experiences ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.experiences && data.experiences.map((experience: { article: string, link: string }) =>
                    <Experience>
                        <h4>{experience.article}</h4>
                        <a href={experience.link}>{experience.link}</a>
                    </Experience>)}
            </Step>
            <Step>
                <StepHead>
                    <h3>Referências</h3>
                    <div onClick={() => handleFieldClick('references')}>
                        {dataState.references ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.references && data.references.map(reference =><h4>{reference}</h4>)}
            </Step>
        </>

    )
}

const Experience = styled.div`
    h4 {
        margin-bottom: 2px;
        font-weight: 500;
    }
    a{
        margin-bottom: 2px;
        border-bottom: 5px;
    }
    margin-bottom: 10px;
`
export default About;    