import { api, setAuthHeader } from "../api";

interface User {
  id: number;
  username: string;
  checked: boolean;
  completed: boolean;
  createdAt: string;
  createdBy: string;
  deleted: boolean;
  email: string;
  firstName: string;
  picture: string;
  role: string;
  updatedAt: string;
  updatedBy: string;
  __v: number;
  _id: string;
}

async function getUsers(token: string): Promise<any> {
  const response = await api.get("user", await setAuthHeader(token));

  return response.data.list;
}

async function deleteUser(id: string, token: string): Promise<any> {
  const response = await api.delete(`user/${id}`, await setAuthHeader(token));

  return response.data;
}

async function checkUser(id: string, token: string): Promise<any> {
  const response = await api.patch(
    `user/${id}/check`,
    {},
    await setAuthHeader(token)
  );

  return response.data;
}

async function getUser(id: string, token: string): Promise<any> {
  const response = await api.get(`user/${id}`, await setAuthHeader(token));

  return response.data;
}

async function updateUser(id: string, token: string, data: User): Promise<any> {
  const response = await api.patch(
    `user/${id}`,
    data,
    await setAuthHeader(token)
  );

  return response.data;
}

async function checkRole(token: string): Promise<any> {
  const response = await api.get(
    `/login-google/check-role`,
    await setAuthHeader(token)
  );

  return response.data;
}

export { getUsers, getUser, updateUser, deleteUser, checkUser, checkRole };
