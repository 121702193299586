import { api, setAuthHeader } from "../api";

interface SignUpData {
  university: string;
  discipline: string;
  specialization: string;
  role: string;
  experience?: string;
}

async function signUp(token: string, data: SignUpData): Promise<any> {
  const response = await api.post("user/profile", data, await setAuthHeader(token));
  return response.data;
}

export {
  signUp
};
