import { getUsers } from "api";
import React from "react";
import { styled } from "styled-components";
import { LoadingDashBoard, Table } from "UI/components";
import { useQuery } from "react-query";
import { useUserMutations } from "hooks/use-user-mutation";
import { useToken } from "hooks";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useUserRepository } from "repositories";

interface User {
  id: number;
  username: string;
  checked: false;
  completed: true;
  createdAt: string;
  createdBy: string;
  deleted: boolean;
  email: string;
  firstName: string;
  picture: string;
  role: string;
  updatedAt: string;
  updatedBy: string;
  __v: number;
  _id: string;
}

const UsersManager: React.FC = () => {
  const { deleteUserMutation } = useUserMutations();

  const { user } = useUserRepository();
  const token = user?.accessToken;

  const { data, error, isLoading } = useQuery<User[], AxiosError>("users", () =>
    getUsers(token)
  );

  if (error?.response?.status) {
    toast.error(
      "Deu ruim no servidor ou você está usando um com o token inválido, tente refazer o login",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    console.error(error);
    localStorage.clear();

    return <Navigate to="/" />;
  }

  const handleEditUser = (userToEdit: User) => {
    console.log(`Editando usuário: ${userToEdit.username}`);
  };

  const handleDeleteUser = (userIdToDelete: string) => {
    deleteUserMutation.mutate({ userId: userIdToDelete, token });
  };

  const headers = ["Válido", "Role (Papel)", "Nome", "Email", "Ver", "Ações"];

  if (isLoading) {
    return <LoadingDashBoard />;
  }

  return (
    <PageContainer>
      <Table
        data={data}
        headers={headers}
        onEdit={handleEditUser}
        onDelete={handleDeleteUser}
        renderConfig={{
          fields: ["checked", "role", "username", "email"],
        }}
      />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  margin: 30px 0px;
`;

export default UsersManager;
