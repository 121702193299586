import { UserProvider } from "contexts";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer, Graphics, Header, ProtectedRouteGuard, SideBar } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  About,
  Home,
  Contact,
  Experience,
  ExperienceList,
  MethodologyRegister,
  ExperienceForm,
  Methodologies,
  Methodology,
  Signin,
  Signup,
  DashboardAdmin,
  Recommendations,
  UsersManager,
  MethodologyManager,
} from "./pages";
import { Box } from "@mui/material";
import { root } from "./theme/colors/colors";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const isMobile = window.innerWidth < 540;
  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Box
            display='flex'
            flexDirection='column'
            margin='0 auto'
          >
            <Header />
            <Box display='flex' height='auto' mt={isMobile ? "55px" : "99.5px"}>
              <SideBar />
              <Box
                width='100%'
                px={4}
                py={1}
                ml={isMobile ? 0: "80px"}
                bgcolor={root[0]}
              >
                <Routes>
                  <Route path="*" element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/experience-form/:id" element={<ExperienceForm />} />
                  {/*<Route path="/experience-list" element={<ExperienceList />} />*/}
                  {/*<Route path="/experience" element={<Experience />} />*/}
                  <Route
                    path="/methodology-register"
                    element={<MethodologyRegister />}
                  />
                  <Route path="/methodologies" element={<Methodologies />} />
                  <Route path="/methodology/:id" element={<Methodology />} />
                  <Route path="/login" element={<Signin />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/recommendations" element={<Recommendations />} />
                  <Route
                    path="/admin-dashboard"
                    element={
                      <ProtectedRouteGuard>
                        <DashboardAdmin />
                      </ProtectedRouteGuard>
                    }
                  >
                    <Route path="graphics" element={<Graphics />} />
                    <Route path="users-manager" element={<UsersManager />} />
                    <Route
                      path="methodologies-manager"
                      element={<MethodologyManager />}
                    >
                      <Route
                        path="edit-methodology/:id"
                        element={<MethodologyRegister />}
                      />
                    </Route>
                  </Route>
                </Routes>
              </Box>
            </Box>
            <Footer />
          </Box>
        </BrowserRouter>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </UserProvider>
  );
};

export default App;
