import { blue, root } from "UI/theme/colors/colors";
import styled from "styled-components";

type Props = {
  bgcolor?: string;
  minWidth?: string;
};

const Box = styled.div<Props>`
  display: flex;
  background-color: ${({ bgcolor }) => bgcolor ?? root[0]};
  min-height: ${({ minWidth }) => minWidth ?? '450px'};
  max-height: ${({ minWidth }) => minWidth ?? '450px'};
  align-items: center;
  @media (max-width: 1240px) {
    min-height: auto;
    margin-top: 8px;
  }
`;

export { Box };
