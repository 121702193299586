import React from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material"
import { root } from "UI/theme/colors/colors";

type UserModeProps = {
    isExpanded: boolean,
    userMode: string,
    handleUserMode: React.Dispatch<React.SetStateAction<string>>
}

export const UserMode: React.FC<UserModeProps> = ({ isExpanded, userMode, handleUserMode }) => {
    const handleChange = (event) => {
        handleUserMode(event.target.value);
    };

    const maxWidth = isExpanded ? 'auto' : '32px';

    return (
        <Box
            pl={2}
            m={1}
            maxWidth={maxWidth}
            overflow='hidden'
            display='flex'
        >
            <Typography
                sx={{
                    maxWidth,
                    fontFamily: 'Cormorant Garamond',
                    fontSize: '22px',
                    lineHeight: '14px',
                    color: '#002140',
                    margin: '3px 8px 0 0',
                    whiteSpace: 'nowrap',
                }}
                variant='h6'
            >
                Mode:
            </Typography>
            <Select
                value={userMode}
                onChange={handleChange}
                sx={{
                    width: maxWidth,
                    overflow: 'hidden',
                    border: 'none !important',
                    '.MuiSvgIcon-root ': {
                        display: isExpanded ? 'block' : 'none',
                        color: root[900]
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '.MuiInputBase-root': {
                        border: 'none !important',
                    },
                    '.MuiInputBase-input': {
                        padding: '0',
                        color: root[900]
                    }
                }}
            >
                <MenuItem value="user">Usuário</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
            </Select>
        </Box>
    );
};
