interface Step {
  name: string;
  description: string;
}

interface MinMax {
  min: number;
  max: number;
}

interface Experiences {
  article: string;
  link: string;
}

interface dataModel {
  name: string;
  about: string;
  subjects: string;
  contents: string;
  timeToApply: string;
  participants: string;
  materials: string;
  planningTime: string;
  programmingLanguages: string;
  principles: string;
  howToPlan: string;
  howToEvaluate: string;
  faculty: string;
  student: string;
  observedMetrics: string;
  aboutSteps: string;
  steps: Step[];
  roles: any[];
  experiences: Experiences[];
  advantages: string;
  challenges: string;
  considerations: string;
  references: string;
}

export default class MethodologyRegisterModel {
  private name: string;
  private about: string;
  private subjects: string;
  private contents: string;
  private timeToApply: MinMax;
  private participants: MinMax;
  private materials: string;
  private planningTime: string;
  private programmingLanguages: string;
  private principles: string;
  private howToPlan: string;
  private howToEvaluate: string;
  private roles: any[];
  private observedMetrics: string;
  private aboutSteps: string;
  private steps: Step[];
  private stepsNumber: number;
  private advantages: string;
  private challenges: string;
  private considerations: string;
  private references: string;
  private experiences: { article: string; link: string }[];

  constructor(data: dataModel) {
    this.name = data.name;
    this.about = data.about;
    this.subjects = data.subjects;
    this.contents = data.contents;
    this.timeToApply = this.parseMinMax(data.timeToApply);
    this.participants = this.parseMinMax(data.participants);
    this.materials = data.materials;
    this.planningTime = data.planningTime;
    this.roles = data.roles;
    this.programmingLanguages = data.programmingLanguages;
    this.principles = data.principles;
    this.howToPlan = data.howToPlan;
    this.stepsNumber = data.steps.length;
    this.howToEvaluate = data.howToEvaluate;
    this.observedMetrics = data.observedMetrics;
    this.aboutSteps = data.aboutSteps;
    this.steps = data.steps;
    this.advantages = data.advantages;
    this.challenges = data.challenges;
    this.considerations = data.considerations;
    this.references = data.references;
    this.experiences = data.experiences;
  }

  private parseMinMax(timeToApply: string): MinMax {
    const [min, max] = timeToApply.split("-").map(Number);
    return {
      min,
      max,
    };
  }

  static parseMinMaxToString(minMax: MinMax): string {
    return `${minMax.min}-${minMax.max}`;
  }

  static build(data: dataModel): MethodologyRegisterModel {
    return new MethodologyRegisterModel(data);
  }
}
