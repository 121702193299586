const programmingLanguages= [
    { label: 'C', value: 'c' },
    { label: 'C++', value: 'c++' },
    { label: 'C#', value: 'c#' },
    { label: 'Java', value: 'java' },
    { label: 'Python', value: 'python' },
    { label: 'JavaScript', value: 'javascript' },
    { label: 'Ruby', value: 'ruby' },
    { label: 'TypeScript', value: 'typescript' },
    { label: 'Swift', value: 'swift' },
    { label: 'Kotlin', value: 'kotlin' },
    { label: 'Rust', value: 'rust' },
    { label: 'Dart', value: 'dart' },
    { label: 'Outras', value: 'others' }
];

export default programmingLanguages;