import React from "react";
import { CustomButton } from "./button.styled";
import { ButtonProps } from "./button.types";
const Button: React.FC<ButtonProps> = ({
  children,
  color,
  width,
  height,
  radius,
  bgcolor,
  type,
  onclick
}) => {
  return (
    <CustomButton
      color={color}
      width={width}
      height={height}
      radius={radius}
      bgcolor={bgcolor}
      type={type}
      onClick={onclick}
    >
      {children}
    </CustomButton>
  );
};

export default Button;
