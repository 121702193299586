import { Container } from "UI/components/shared/pages-styles/pages-styles";
import React from "react";
import { Box } from "@mui/material";
import { arquitetura, modelo } from "assets/index";
import { Typography } from "@mui/material";

const About: React.FC = () => {

    return (
        <Container>
            <Box>
                <Typography my={2} variant="h4" sx={{ textAlign: "center" }}>Sobre o CollabProg</Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    style={{
                        width: '100%',
                    }}
                >
                    <Typography
                        my={2}
                        variant="body1"
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            fontWeight: 400,
                            lineHeight: '30px'
                        }}
                        mt={2}
                    >
                        O CollabProg é um repositório colaborativo que tem como objetivo principal apoiar docentes durante a adoção de metodologias ativas enquanto ensinam conteúdos de programação. O CollabProg
                        auxiliará o professor na identificação e escolha de metodologias ativas (MAs) de acordo com seu contexto
                        de ensino e que atenda às necessidades pedagógicas. Além disso, disponibilizará um conjunto de guidelines
                        específicas que descreverão os passos para que os docentes adotem MAs em sala de aula. Desta forma, os docentes não precisarão mais buscar em vários artigos, livros, websites ou fóruns maneiras de como implementar determinada MA.
                    </Typography>

                    <Typography
                        my={2}
                        variant="body1"
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            fontWeight: 400,
                            lineHeight: '30px'
                        }}
                        mt={2}
                    >
                        O CollabProg está disponível em uma plataforma na Internet para que qualquer docente possa acessá-lo. A Figura 1 apresenta a arquitetura da informação do CollabProg.
                        Os módulos da plataforma são exibidos em uma sequência de visualização e o docente não precisará realizar nenhum cadastro para acessar a plataforma e ter acesso a todas as suas funcionalidades.
                    </Typography>

                    <img className="imagem" src={arquitetura} alt={arquitetura} style={{ maxWidth: "60%", alignSelf: "center" }} />

                    <Typography
                        my={2}
                        variant="body2"
                        textAlign='center'
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            lineHeight: '30px',
                            fontWeight: 700
                        }}
                        mt={2}
                    >
                        Figura 1 - Estrutura do CollabProg
                    </Typography>
                    <Typography
                        my={2}
                        variant="body1"
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            fontWeight: 400,
                            lineHeight: '30px'
                        }}
                        mt={2}
                    >
                        Para classificar as MAs que fazem parte do CollabProg, agrupamos o conhecimento sobre cada metodologia em um modelo conceitual inspirado nas propostas de Sobrinho et al. (2016) e de Silva et al. (2020). Para isso,
                        inicialmente foi definido o domínio e escopo do conhecimento que foram construídos a partir dos resultados de um mapeamento da literatura, que pode ser visto em Calderon, I., Silva, W., & Feitosa, E. (2023).
                        <br />O escopo deste modelo é fornecer suporte aos docentes ao ensino de programação no ensino superior, por meio do conhecimento organizado e representado semanticamente, facilitando a sua difusão e uso de metodologias ativas. Desse modo,
                        estruturamos as informações coletadas das MAs em um modelo conceitual, apresentado na Figura 2.
                    </Typography>

                    <img className="imagem" src={modelo} alt={modelo} style={{ maxWidth: "60%", alignSelf: "center" }} />
                    <Typography
                        my={2}
                        variant="body2"
                        textAlign='center'
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            lineHeight: '30px',
                            fontWeight: 700
                        }}
                        mt={2}
                    >
                        Figura 2 - Modelo conceitual do CollabProg
                    </Typography>
                    <Typography
                        my={2}
                        variant="body1"
                        style={{
                            fontFamily: 'Cormorant Garamond',
                            fontSize: '20px',
                            width: '100%',
                            fontWeight: 400,
                            lineHeight: '30px'
                        }}
                        mt={2}
                    >
                        Em relação à curadoria dos conteúdos que serão compartilhados no CollabProg será realizado um processo de triagem que visará garantir a confiabilidade dos conteúdos apresentados, para que se tenha a adoção efetiva das MAs no ensino de programação.
                        Além disso, para a curadoria, são adotados critérios que permitem avaliar os conteúdos que serão disponibilizados no repositório, para evitar frustações dos usuários que utilizarão do repositório.
                        <br />
                        Por fim, espera-se que o CollabProg seja um apoio tecnológico que reunirá, em um único portal na Internet, estratégias de como conduzir a adoção de diferentes MAs para o ensino de programação, disponibilizando  exemplos, sugestões de atividades, opções de suporte,
                        ferramental disponível e adotado pela comunidade, experiências sobre a adoção das metodologias em cenários diferentes, resultados alcançados por outros docentes e pontos positivos e negativos sobre a MAs adotada.
                        <br />
                        Vamos lá! Use o CollabProg e nos conte sua experiência 😊.
                    </Typography>

                </Box>

            </Box>
        </Container >
    )
}

export default About;