import { api, setAuthHeader } from "../api";

interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

async function getDashboardMethodologies(token: string): Promise<any> {
  const response = await api.get("dashboard/methodology", await setAuthHeader(token));

  return response.data;
}


async function getDashboardUser(token: string): Promise<any> {
  const response = await api.get("dashboard/user", await setAuthHeader(token));

  return response.data;
}

export {
  getDashboardUser,
  getDashboardMethodologies
};
