import { blue } from "UI/theme/colors/colors";
import { styled } from "styled-components";

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(49,49,49,0.8);
    z-index: 3;
`;

const PopupContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${blue[50]};
    padding: 14px 28px;
    border-radius: 8px;
    border-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 350px;
    height: 250px;
    z-index: 4;

    h3 {
        font-family: 'Cormorant Garamond';
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }
    p {
        font-size: 20px !important;
        font-weight: 500;
        margin-left: 5px !important;
        text-align: center;
    }
    @media (max-width: 1240px) {
        width: 250px;
        height: 150px;
        h3 {
            font-size: 14px;
        }
        p {
            font-size: 14px !important;
        }
    }
`;

const Title = styled.p`
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
    @media (max-width: 1240px) {
        &:hover {
            transform: none;
            text-shadow: none;
        }
    }
`;

const ButtonsGrid = styled.div`
  display:flex;
  flex-direction: column;
  min-height: '200px';
  justify-content: space-around;
    img {
        height: 20px;
    }
  & div {
        display: flex;
        align-items: center;
    }
    & button {
        font-family: 'Cormorant Garamond';
        margin-top: 10px;
    }
`;

export { ButtonsGrid, PopupContent, Overlay, Title };