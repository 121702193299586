import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useQuery } from "react-query";
import LoadingDashBoard from "./loading/loading.component";
import { getDashboardMethodologies, getDashboardUser, getMethodologies } from "api";
import { PieChart, Pie, Cell, BarChart, LineChart, Line, XAxis, YAxis, Tooltip, Bar, Legend } from 'recharts';
import { useUserRepository } from "repositories";

interface TimelineChartProps {
  data: any[];
  xKey?: string;
  yKey?: string;
  type?: string;
  title?: string;
  clearDataEnabled?: boolean;
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  do {
    color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (color === '#000000');
  return color;
};

const generateRandomColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(getRandomColor());
  }
  return colors;
};

const formatMethodologiesData = (methodologies: any[]) => {
  return methodologies.reduce((acc, methodology) => {
    const createdAt = new Date(methodology.createdAt);
    const formattedDate = createdAt.toLocaleDateString('pt-BR');
    const methodName = methodology.name;

    if (acc[formattedDate]) {
      acc[formattedDate].names.push(methodName)
      acc[formattedDate].count++;
    } else {
      acc[formattedDate] = { date: formattedDate, count: 1, names: [methodName] };
    }

    return acc;
  }, {});
}


const formatUserData = (users) => {
  const universitiesByStudents = users.map((item, index) => ({
    name: item.university || `Universidade ${index + 1}`,
    count: item.totalStudents
  }));
  const universitiesByTeacher = users.map((item, index) => ({
    name: item.university || `Universidade ${index + 1}`,
    count: item.totalProfessors
  }));

  universitiesByStudents?.sort((a, b) => a.name.localeCompare(b.name));
  universitiesByTeacher?.sort((a, b) => a.name.localeCompare(b.name));

  return {
    students: universitiesByStudents,
    professors: universitiesByTeacher
  }

}

const TimelineChart: React.FC<TimelineChartProps> = ({
  data,
  title,
  type,
  xKey,
  yKey,
  clearDataEnabled
}) => {

  const chartData = Object.values(data)
  let clearData = chartData;

  if (clearDataEnabled) {
    clearData = chartData.filter((entry) => entry.count);
  }

  const [colors, setColors] = React.useState(generateRandomColors(clearData.length));
  
  return (
    <Box
      px={3}
      my={2}
      width='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      <Typography width='100%' textAlign='center' mb={2} variant="h5">{title}</Typography>
      <PieChart width={700} height={400}>
        <Pie
          data={clearData}
          dataKey={yKey}
          nameKey={xKey}
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          label
        >
          {clearData.map((entry, index) => {
            return (entry.count || entry.averageStars) && <Cell key={`cell-${entry}`} fill={colors[index]} />
          })}
        </Pie>
        <Tooltip />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{ paddingLeft: '20px', maxWidth: '300px' }}
        />
      </PieChart>
    </Box>
  );

};

const graphicsTypes = {
  qtMetodologies: 'qtMetodologies',
  qtStudents: 'qtStudents',
  qtProfessors: 'qtProfessors',
  methodolofyAverage: 'methodolofyAverage'
}

const Graphics: React.FC = () => {
  const { user } = useUserRepository();
  const token = user?.accessToken;

  const [graphicsType, setGraphicsTypes] = useState(graphicsTypes.qtMetodologies);

  const { data: methodologies, error: methodologiesError, isLoading: methodologiesLoading } = useQuery<any>("metholodologies", () =>
    getMethodologies(token),
    {
      cacheTime: 1000 * 60 * 60 * 24
    }
  );

  const { data: dashMethodologies, isLoading: dashMethodologiesLoading } = useQuery<any>("dashMethodologies", () =>
    getDashboardMethodologies(token),
    {
      cacheTime: 1000 * 60 * 60 * 24
    }
  );

  const { data: users, error: usersError, isLoading: usersLoading } = useQuery<any>("users", () => getDashboardUser(token),
    {
      enabled: !methodologiesLoading,
      cacheTime: 1000 * 60 * 60 * 24
    })

  if (methodologiesLoading || usersLoading) {
    return <LoadingDashBoard />;
  }

  const { students, professors } = formatUserData(users);
  return (
    <Box pt={2}>
      <FormControl margin="normal" sx={{ maxWidth: '300px', display: "flex" }}>
        <Typography variant="h6" mb={2}>Filtrar por:</Typography>
        <Select
          labelId="chart-type-label"
          value={graphicsType}
          onChange={(e) => setGraphicsTypes(e.target.value)}
        >
          <MenuItem value={graphicsTypes.qtMetodologies}>Qt. Metodologias Cadastradas</MenuItem>
          <MenuItem value={graphicsTypes.qtProfessors}>Qt. Professores Por Universidade</MenuItem>
          <MenuItem value={graphicsTypes.qtStudents}>Qt. Alunos Por Universidade</MenuItem>
          <MenuItem value={graphicsTypes.methodolofyAverage}>Média de Avaliação das Metodologias</MenuItem>
        </Select>
      </FormControl>
      {graphicsType === graphicsTypes.qtMetodologies && <TimelineChart
        clearDataEnabled
        title="Quantidade de Metodologias Cadastradas ao Longo do Tempo"
        data={formatMethodologiesData(methodologies)}
        xKey="date"
        yKey="count"
      />}
      {graphicsType === graphicsTypes.qtProfessors && <TimelineChart
        clearDataEnabled
        title="Quantidade de alunos por universidade"
        xKey="name"
        yKey="count"
        data={students}
      />}
      {graphicsType === graphicsTypes.qtStudents && <TimelineChart
        clearDataEnabled
        title="Quantidade de professores por universidade"
        xKey="name"
        yKey="count"
        data={professors}
      />}
      {graphicsType === graphicsTypes.methodolofyAverage && <TimelineChart
        title="Média de estrelas por metodologia"
        xKey="methodologyName"
        yKey="averageStars"
        data={dashMethodologies}
      />}
    </Box>
  );
};

export default Graphics;
