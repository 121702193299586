import { root } from 'UI/theme/colors/colors';
import styled from 'styled-components';

type Props = {
    aling?: string
}

const Box = styled.div<Props>`
    margin: 0 0 8px 0;
    background-color: ${root[0]};
    max-width: 1176px;
    display: flex;
    box-sizing: border-box;
    padding: 0 8px;
    flex-direction: column;
    justify-content: center;
    h3 {
    font-family: Cormorant Garamond;
    font-size: 36px;
    font-weight: 500;
    line-height: 24px;
    margin: 24px 0 40px;
    align-self: center;
    }

    @media (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
        padding: 0 8px;
        margin: 10px 0;
        h3 {
        font-size: 18px;
        margin-bottom: 25px;
        margin-top: 16px;         
        text-align: left;
        }
    }
`;

const Label = styled.label`
    pointer-events: none;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    width: "100%";
    margin-bottom: 8px !important;
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`;

const Checkbox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    max-width: 500px;
`;

const ButtonForm = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 24px;
    grid-gap: 10px;

    button {
        &:hover {
            background-color: #64A2A4;
        }
    }
`;


export { Box, Checkbox, Label, ButtonForm };