import React, { createContext, useContext, ReactNode } from 'react';
import useLocalStorage from 'use-local-storage';

export type Feedback = {
    nameUser?: string;
    stars?: number;
    idMethodology?: string;
    comment?: string;

};

type FeedbackRepositoryContextType = {
    feedback: Feedback | null;
    setter: (feedback: Feedback | null) => void;
    resetForm: () => void;
};

export const FeedbackRepositoryContext = createContext<FeedbackRepositoryContextType | undefined>(
    undefined
);

export const FeedbackRepositoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [feedback, setFeedback] = useLocalStorage<Feedback>("feedback", {});

    const setter = state => {
        setFeedback(state)
    }

    const resetForm = (): void => {
        setter({});
    };

    return (
        <FeedbackRepositoryContext.Provider value={{ feedback, setter, resetForm }}>
            {children}
        </FeedbackRepositoryContext.Provider>
    );
};

export const useFeedbackRepository = (): FeedbackRepositoryContextType => {
    const feedbackRepository = useContext(FeedbackRepositoryContext);
    return {
        ...feedbackRepository
    };
};
