import { Button, Loading, MethodologyCard } from "UI/components";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import { Box as Box2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "./methodology.styled";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import HowImplementComponent from "./how-implement/how-implement.component";
import About from "./about/about.component";
import ExperienceList from "../experience-list/experience-list.page";
import { blue, grey, root } from "UI/theme/colors/colors";
import { getMethodology } from "api";
import { Typography } from "@mui/material";

const Methodologies: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState({
    implements: true,
    about: false,
    feedback: false,
    generalfeedback: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMethodology(id, "");
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleTabClick = (key: string) => {
    const updatedTabs = {
      implements: false,
      about: false,
      feedback: false,
      generalfeedback: false,
    };

    updatedTabs[key] = true;
    setSelectedTab(updatedTabs);
  };

  return (
    <>
      <Container>
        <>
          {loading ? (
            <Loading size={85} color="blue" />
          ) : (
            <Box2>
              <Typography my={2} variant="h4" sx={{ textAlign: "center" }}>{data.name}</Typography>
              <MethodologyCard about={data.about} image={data.image}>
                <Box2 
                bgcolor={grey[150]} 
                display="flex" 
                justifyContent="space-around" 
                mt={2}
                borderRadius={2}
                >
                  <Box2
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body1">Tempo de aplicação</Typography>
                    <Typography variant="body1">{`${data.timeToApply.min}-${data.timeToApply.max} min`}</Typography>
                  </Box2>
                  <Box2
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body1">Tamanho do grupo</Typography>
                    <Typography variant="body1">{`${data.participants.min}-${data.participants.max}`}</Typography>
                  </Box2>
                </Box2>
              </MethodologyCard>
              <Buttons>
                <div>
                  <Button
                    height="40px"
                    bgcolor={selectedTab.implements ? blue[100] : grey[150]}
                    color={selectedTab.implements ? root[0] : "#2c2c2c"}
                    onclick={() => handleTabClick("implements")}
                  >
                    Como implementar
                  </Button>
                  <Button
                    height="40px"
                    bgcolor={selectedTab.about ? blue[100] : grey[150]}
                    color={selectedTab.about ? root[0] : "#2c2c2c"}
                    onclick={() => handleTabClick("about")}
                  >
                    Mais sobre a metodologia
                  </Button>
                </div>
                <Button
                  height="40px"
                  bgcolor={selectedTab.feedback ? blue[100] : grey[150]}
                  color={selectedTab.feedback ? root[0] : "#2c2c2c"}
                  onclick={() => handleTabClick("feedback")}
                >
                  Visualizar Feedbacks
                </Button>
              </Buttons>
              {selectedTab.implements && <HowImplementComponent data={data} />}
              {selectedTab.about && <About data={data} />}
              {selectedTab.feedback && <ExperienceList name={data.name} />}
            </Box2>
          )}
        </>
      </Container>
    </>
  );
};

const Buttons = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    width: 420px;
  }
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const BoxInformation = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  background-color: ${grey[150]};
  border-radius: 8px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      max-width: 400px;
    }
  }
`;

export default Methodologies;
