import styled from "styled-components";
import { AlertProps } from "./image-and-email-wrapper.types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;

  figure {
    width: 54px;
    height: 54px;
    background: red;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0px !important;
    margin-left: 5px !important;
  }

  div h3 {
    font-family: Cormorant Garamond;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: start;
    color: #000000 !important;
    margin: 0px;
  }
  span {
    cursor: copy;
  }
`;

const AlertBox = styled.div<AlertProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: opacity 0.5s linear;
  opacity: ${({ fadeoutanimation }) => (fadeoutanimation === true ? 0 : 1)};
`;

export { Wrapper, AlertBox };
