import { api, setAuthHeader } from "../api";
import { Feedback } from "repositories/feedback-repository/feedback.repository";

async function postFeedback(token: string, data: Feedback): Promise<any> {
  const response = await api.post("feedback", data, await setAuthHeader(token));
  return response.data;
}

async function getFeedbacks(id: string): Promise<any> {
  const response = await api.get(`feedback/${id}`);
  return response.data;
}

export {
  postFeedback,
  getFeedbacks
};
