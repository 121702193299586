import React, { ReactNode } from "react";
import { Box } from "./section.styled";

type SectionProps = {
  bgcolor?: string;
  minWidth?: string;
  children: ReactNode;
};

const Section: React.FC<SectionProps> = ({ bgcolor, minWidth, children }) => {
  return <Box minWidth={minWidth} bgcolor={bgcolor}>{children}</Box>;
};

export default Section;
