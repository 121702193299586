import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserProfile from "../user-profile/user.profile.component";
import { oCollabbProgT } from "assets/index";
import { Box } from "@mui/material";
import { blue } from "UI/theme/colors/colors";

const Header: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 540;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        position="fixed"
        bgcolor={blue[50]}
        px={isMobile ? 0 : 8}
        py={1}
        zIndex={10}
      >
        <Link to="/">
          <Box sx={{ 
            '& img': {
              height: isMobile ? "40px" : "80px",
            }
            }}>
            <img src={oCollabbProgT} alt={oCollabbProgT}  />
          </Box>
        </Link>
        {isMobile && <UserProfile />}
      </Box>
  );
};

export default Header;
