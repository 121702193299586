import styled from "styled-components";
import { TextareaProps } from "./custom-textarea.types";

const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #c4c4c4;
`;

const CustomLabel = styled.label`
  pointer-events: none;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const CustomAreaContainer = styled.div<Omit<TextareaProps, "label">>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
`;

const TextareaWithoutEffect = styled.textarea<TextareaProps>`
  padding: 10px;
  height: ${({ height }) => height};
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  resize: none;
  margin-bottom: 8px;
  border-radius: 8px;
`;

const Textarea = styled.textarea<
  Omit<TextareaProps, "label" | "width" | "height">
>`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  resize: none;
  border-radius: 8px;
  &:focus ~ label,
  &:valid ~ label {
    transform: translateY(-25px);
    font-size: 12px;
    color: #aaa;
    left: 0px;
  }
`;

const ContainerTextarea = styled.div<Omit<TextareaProps, "label">>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;
`;

export { ContainerTextarea, Textarea, Label, CustomLabel, TextareaWithoutEffect, CustomAreaContainer };
