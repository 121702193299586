import { createContext, ReactNode } from "react";
import { useLocalStorage } from "hooks";

interface UserData {
  username?: string;
  firstName?: string;
  email?: string;
  picture?: string;
  role?: string;
  university?: string;
  experience?: string;
  education?: string;
  discipline?: string;
  completed?: string;
  checked?: string;
  accessToken?: string;
}

interface UserContextProps {
  userData: UserData;
  setUserData: (value: string) => void;
}

const validateUserData = (userData: Partial<UserData>): UserData => {
  const defaultUserData: UserData = {
    username: "",
    firstName: "",
    email: "",
    picture: "",
    role: "",
    university: "",
    experience: "",
    education: "",
    discipline: "",
    completed: "",
    checked: "",
    accessToken: "",
  };

  return {
    ...defaultUserData,
    ...userData,
  };
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

export default UserContext;

export function UserProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { storedValue: userData, setValue: setUserData } = useLocalStorage(
    "user",
    validateUserData({})
  );

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
}
