import React from "react";
import { ContactForm, ImageAndEmail, Separator } from "UI/components";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import styled from "styled-components";
import { contacts } from "data";
import { root } from "UI/theme/colors/colors";
import { Typography } from "@mui/material";

const Contact: React.FC = () => {
  return (
    <>
      <Container>
        <Box>
          {/*<Typography my={2} variant="h4" sx={{ textAlign: "center" }}></Typography>

          <h3>Pesquisadores Envolvidos</h3>
          <Separator
            width={"100%"}
            height={"1px"}
            bgcolor={"#cbcbcb"}
          ></Separator>
          <ContainerInformation>
            <Column>
              {contacts.boss.map((contact, index) => {
                return (
                  <ImageAndEmail
                    key={index}
                    src={contact.image}
                    alt={contact.alt}
                    name={contact.name}
                    email={contact.email}
                    office={contact.office}
                  />
                );
              })}
            </Column>
            <Separator
              width={"1px"}
              height={"304px"}
              marginleft={"8px"}
              bgcolor={"#cbcbcb"}
            />
            <Column>
              {contacts.front.map((contact, index) => {
                return (
                  <ImageAndEmail
                    key={index}
                    src={contact.image}
                    alt={contact.alt}
                    name={contact.name}
                    email={contact.email}
                    office={contact.office}
                  />
                );
              })}
            </Column>
            <Separator
              width={"1px"}
              height={"304px"}
              marginleft={"8px"}
              bgcolor={"#cbcbcb"}
            />
            <Column>
              {contacts.back.map((contact, index) => {
                return (
                  <ImageAndEmail
                    key={index}
                    src={contact.image}
                    alt={contact.alt}
                    name={contact.name}
                    email={contact.email}
                    office={contact.office}
                  />
                );
              })}
            </Column>
            <Separator
              width={"1px"}
              height={"304px"}
              marginleft={"8px"}
              bgcolor={"#cbcbcb"}
            />
            <Column>
              {contacts.recomm.map((contact, index) => {
                return (
                  <ImageAndEmail
                    key={index}
                    src={contact.image}
                    alt={contact.alt}
                    name={contact.name}
                    email={contact.email}
                    office={contact.office}
                  />
                );
              })}
            </Column>
          </ContainerInformation>*/}
          <ContactForm />
        </Box>
      </Container>
    </>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    text-align: left !important;
    font-family: "Cormorant Garamond";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 14px;
    color: #308099 !important;
  }
 
`;

const ContainerInformation = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1240px) {
        flex-direction: column;
    }
`;

const Box = styled.div`
  margin: 0 0 8px 0;
  background-color: ${root[0]};
  display: flex;
  box-sizing: border-box;
  padding: 0 8px;
  flex-direction: column;
  justify-content: center;
  div {
    margin-bottom: 12px;
  }
  h3 {
    font-family: "Cormorant Garamond";
    font-weight: 700;
    font-size: 35px;
    width: 100%;
    margin-bottom: 35px;
    margin-top: 45px;
    text-align: center;
    color: #2c2c2c;
  }
  p {
    font-family: Cormorant Garamond;
    font-size: 20px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 35px;
    b {
      font-weight: bold;
    }
  }
  @media (max-width: 1240px) {
      margin: 10px auto;
      padding: 0 8px;
    }
`;
export default Contact;
