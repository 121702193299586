import { useContext } from "react";

import UserContext from "contexts/user-context/user.context";
interface User {
  user: {
    username: string;
    firstName: string;
    email: string;
    picture: string;
    role: string;
    completed: boolean;
    checked: boolean;
    accessToken: string;
  };
}
const useToken = (): string => {
  const { userData: user } = useContext(UserContext);
  return user?.accessToken;
};

export default useToken;
