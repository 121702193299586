import React from "react";

type TableHeaderProps = {
  headers: string[];
};

const TableHeader: React.FC<TableHeaderProps> = ({ headers }) => {
  return (
    <thead>
      <tr
        style={{
          backgroundColor: "#428bca",
          color: "#fff",
          borderTop: "none",
        }}
      >
        {headers.map((header) => (
          <th>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
