import ReactLoading from "react-loading";
import { styled } from "styled-components";

const LoadingDashBoard: React.FC = () => {
  return (
    <LoadingWrapper>
      <ReactLoading
        type={"spinningBubbles"}
        color={"#007bff"}
        height={150}
        width={150}
      />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingDashBoard;
