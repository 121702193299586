import { grey } from 'UI/theme/colors/colors';
import styled from 'styled-components';

type Props = {
    imgurl: string,
    textcolor?: string,
    mb?: string,
    shadow?: boolean
}

const Main = styled.div`
 max-width: 100%;
 overflow-x: auto;
 overflow-y: hidden;
 padding: 24px 0 0 0;
 max-height: 350px;
 @media (max-width: 1240px) {
    max-height: 300px;
    max-width: 370px;
  }
`

const CardCarousel = styled.div<Props>`
 height: 350px;
 border-radius: 8px;
 background-repeat: no-repeat;
 background-image: url(${({ imgurl }) => imgurl});
 background-size: 370px 350px;
 opacity: 0.9;
 div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 16px;
    p {
        font-family: 'Cormorant Garamond';
        font-weight: 700;
        color: ${({ textcolor }) => textcolor ?? grey[100]};
        text-shadow: ${({ shadow }) => shadow ? '0px 0px 5px white' : 'none'};
        font-size: 24px;
        text-align: center;
        line-height: 23px;
    }
 }
 @media (max-width: 1240px) {
    min-height:auto;
    height: 300px;
    background-size: 370px 300px;
    div{
        height: 200px;
        min-height:auto;
        p{ 
            font-size: 12px;
        }
    }
  }
`
const Card = styled.div`
 width: 350px !important;
 border-radius: 8px;
 cursor: pointer;
 @media (max-width: 1240px) {
    width: 370px !important;
  }
`;


export {
    Main,
    Card,
    CardCarousel
}