import React from "react";
import { ButtonsGrid, PopupContent, Overlay } from "./continue-register.styled";
import { Button } from "UI/components";
import { googleIcon } from "assets/index";
import { red, root } from "UI/theme/colors/colors";
import { useUserRepository } from "repositories";

interface ContinueRegisterPopupProps {
  closePopup: () => void;
  eraserData: () => void;
}

export const ContinueRegisterPopup: React.FC<ContinueRegisterPopupProps> = ({ closePopup, eraserData }) => {

  const onCloseEraserData = () => {
    eraserData()
    closePopup()
  }
  
  return (
    <>
      <Overlay />
      <PopupContent>
        <h3>Deseja continuar com a criação da metodologia?</h3>
        <ButtonsGrid>
          <Button onclick={closePopup} bgcolor={root[0]} color={root[900]} width={"220px"} height={"40px"}>
            <div>
              <p>Sim</p>
            </div>
          </Button>
          <Button bgcolor={red[50]} color={root[0]} width={"220px"} height={"40px"} onclick={onCloseEraserData}><p>Não</p></Button>
        </ButtonsGrid>
      </PopupContent>
    </>
  );
}

export default { ContinueRegisterPopup };