const specializations = [
    {
        label: 'Graduando',
        value: 'Graduando'
    },
    {
        label: 'Mestre',
        value: 'Mestre'
    },
    {
        label: 'Professor',
        value: 'Professor'
    },
    {
        label: 'Doutor',
        value: 'Doutor'
    },
    {
        label: 'Especialista',
        value: 'Especialista'
    },
    {
        label: 'Pesquisador',
        value: 'Pesquisador'
    },
    {
        label: 'Pós-graduando',
        value: 'Pós-graduando'
    }
]

export default specializations;