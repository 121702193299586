import { LinearStepper } from "UI/components";
import { useEffect, useState } from 'react';
import { Box } from "./experience-form.styled";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import {
    FormContainer,
} from "UI/components/shared/custom-input/custom-input.styled";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useExperienceRepository } from "repositories";
import { FirstStep, FourthStep, SecondStep, ThirdyStep } from "./exprience-forms";
import { checkIfHasEmptyFields, initialErrors } from "repositories/experience-repository/experience.helper";
import { ExperienceRegisterModel } from "models";
import { getMethodology, postExperience } from "api";
import { useToken } from "hooks";

const ExperienceForm: React.FC = () => {

    const { actionsSupported, experience, handleSubmitExperienceData, resetForm } = useExperienceRepository();
    const [errorsData, setErrorsData] = useState(initialErrors);
    const [methodologyName, setMethodologyName] = useState(null);
    const [otherValues, setOtherValues] = useState({ disciplines: '', languages: '' });
    const { id } = useParams();
    const token = useToken();
    const navigate = useNavigate();

    const getMethodologyName = async () => {
        const result = await getMethodology(id, token);
        setMethodologyName(result.name);
    }

    useEffect(() => {
        getMethodologyName();
    }, [])

    const handleSubmit = async () => {
        const data = ExperienceRegisterModel.build({ ...experience, idMethodology: id }, otherValues);

        await postExperience(token, data);
        resetForm();
        navigate('/');
    }

    const [step, setStep] = useState(0);

    return (
        <>
            <Container>
                <Box>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <h3>Conte-nos sua experiencia usando a metodologia</h3>
                        <h4> {methodologyName}</h4>
                    </Box>
                    <FormContainer>
                        <LinearStepper handleSubmit={handleSubmit} stepsNumber={4} hasEmptyFields={() => checkIfHasEmptyFields(experience, step, setErrorsData)} step={step} handleStep={setStep}>
                            {step === 0 && <FirstStep errors={errorsData} experience={experience} handleSubmitExperienceData={handleSubmitExperienceData} />}
                            {step === 1 && <SecondStep errors={errorsData} experience={experience} handleSubmitExperienceData={handleSubmitExperienceData} otherValues={otherValues} setOtherValues={setOtherValues} />}
                            {step === 2 && <ThirdyStep errors={errorsData} experience={experience} handleSubmitExperienceData={handleSubmitExperienceData} />}
                            {step === 3 && <FourthStep actionsSupported={actionsSupported} errors={errorsData} experience={experience} handleSubmitExperienceData={handleSubmitExperienceData} />}
                        </LinearStepper>
                    </FormContainer>
                </Box>
            </Container>
        </>
    );
}

export default ExperienceForm;