import { root } from "UI/theme/colors/colors";
import styled from "styled-components";

const Box = styled.div`
  background-color: ${root[0]};
  max-width: 1176px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    font-family: Cormorant Garamond;
    font-size: 35px;
    font-weight: 500;
    line-height: 24px;
    margin: 10px 0 40px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    margin: 15px 0px;
  }
`;

export {
    Box
}