const universities = [
    {
        label: 'Universidade federal do amazonas',
        value: 'Universidade federal do amazonas'
    },
    {
        label: 'Universidade federal do rio de janeiro',
        value: 'Universidade federal do rio de janeiro'
    },
    {
        label: 'Universidade federal do rio grande do sul',
        value: 'Universidade federal do rio grande do sul'
    },
    {
        label: 'Universidade federal do paraná',
        value: 'Universidade federal do paraná'
    },
    {
        label: 'Universidade federal de santa catarina',
        value: 'Universidade federal de santa catarina'
    },
    {
        label: 'Universidade federal de são paulo',
        value: 'Universidade federal de são paulo'
    },
    {
        label: 'Outro',
        value: 'Outro'
    }
];

export default universities;