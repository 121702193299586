import React, { createContext, useContext, ReactNode } from "react";
import useLocalStorage from "use-local-storage";

export type User = {
  username?: string;
  firstName?: string;
  email?: string;
  picture?: string;
  completed?: boolean;
  checked?: false;
  accessToken?: string;
  lastPage?: string;
  role?: string;
};

type UserRepositoryContextType = {
  user: User | null;
  setter: (user: User | null) => void;
};

export const UserRepositoryContext = createContext<
  UserRepositoryContextType | undefined
>(undefined);

export const UserRepositoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useLocalStorage<User | null>("user", null);

  const setter = (state) => {
    setUser({ ...user, ...state });
  };

  return (
    <UserRepositoryContext.Provider value={{ user, setter }}>
      {children}
    </UserRepositoryContext.Provider>
  );
};

export const useUserRepository = (): UserRepositoryContextType => {
  const userRepository = useContext(UserRepositoryContext);
  return {
    ...userRepository,
  };
};
