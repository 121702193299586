import { Box, Button } from "@mui/material";

interface CustomButtonProps {
    color?: string;
    width?: string;
    height?: string;
    bgcolor?: string;
    variant?: "text" | "outlined" | "contained";
    label: string;
    onclick: any;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ label, color, width, height, onclick, variant }): any => {
    return (
        <Box
            width='100%'
            display='flex'
            justifyContent='flex-end'
        >
            <Button
                onClick={onclick}
                variant={variant}
                sx={{ borderRadius: "8px" }}
            >
                {label}
            </Button>
        </Box>
    )
}

export default CustomButton;