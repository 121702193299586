import styled from "styled-components";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 591px;
  margin: 0 auto;
`;

const Container = styled.div`
  background: rgba(196, 196, 196, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-bottom: 30px;
`;

export { Box, Container };
