interface Step {
  name: string;
  description: string;
}

interface Experiences {
  article: string;
  link: string;
}

const initialData = {
  name: "",
  about: "",
  subjects: "",
  contents: "",
  timeToApply: "",
  participants: "",
  materials: "",
  planningTime: "",
  programmingLanguages: "",
  principles: "",
  howToPlan: "",
  howToEvaluate: "",
  roles: [""],
  student: "",
  observedMetrics: "",
  aboutSteps: "",
  steps: [{ name: "", description: "" }] as Step[],
  experiences: [{ article: "", link: "" }] as Experiences[],
  advantages: "",
  challenges: "",
  considerations: "",
  references: "",
};

const initialErrors = {
  name: false,
  about: false,
  timeToApply: false,
  participants: false,
  howToEvaluate: false,
  roles: false,
  observedMetrics: false,
  steps: false,
  advantages: false,
  challenges: false,
  considerations: false,
  references: false,
};

const requiredFieldsFromSteps = {
  0: ["name", "timeToApply", "participants", "roles"],
  1: ["about"],
  2: ["steps", "howToEvaluate"],
  3: ["observedMetrics", "advantages", "challenges", "considerations"],
  4: ["references"],
}

const resetForm = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>): void => {
  setData(initialData);
};

const handleChange = (
  setData: React.Dispatch<React.SetStateAction<typeof initialData>>,
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
): void => {
  const { id, value } = event.target;
  setData((prevFormData) => ({
    ...prevFormData,
    [id]: value,
  }));
};

const handleRolesChange = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>, index: number, value: string): void => {
  setData((prevFormData) => {
    const roles = [...prevFormData.roles];
    roles[index] = value;
    return {
      ...prevFormData,
      roles,
    };
  });
};

const handleAddRoles = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>): void => {
  setData((prevFormData) => ({
    ...prevFormData,
    roles: [...prevFormData.roles, ""],
  }));
};

const handleRemoveRole = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>, index: number): void => {
  setData((prevFormData) => {
    const roles = [...prevFormData.roles];
    roles.splice(index, 1);
    return {
      ...prevFormData,
      roles,
    };
  });
};

const handleStepChange = (
  setData: React.Dispatch<React.SetStateAction<typeof initialData>>,
  index: number,
  field: "name" | "description",
  value: string
): void => {
  setData((prevFormData) => {
    const steps = [...prevFormData.steps];
    steps[index][field] = value;
    return {
      ...prevFormData,
      steps,
    };
  });
};

const handleAddStep = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>): void => {
  setData((prevFormData) => ({
    ...prevFormData,
    steps: [...prevFormData.steps, { name: "", description: "" }],
  }));
};

const handleRemoveStep = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>, index: number): void => {
  setData((prevFormData) => {
    const steps = [...prevFormData.steps];
    steps.splice(index, 1);
    return {
      ...prevFormData,
      steps,
    };
  });
};

const handleExperienceChange = (
  setData: React.Dispatch<React.SetStateAction<typeof initialData>>,
  index: number,
  field: "article" | "link",
  value: string
): void => {
  setData((prevFormData) => {
    const experiences = [...prevFormData.experiences];
    experiences[index][field] = value;
    return {
      ...prevFormData,
      experiences,
    };
  });
};

const handleAddExperience = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>): void => {
  setData((prevFormData) => ({
    ...prevFormData,
    experiences: [...prevFormData.experiences, { article: "", link: "" }],
  }));
};

const handleRemoveExperience = (setData: React.Dispatch<React.SetStateAction<typeof initialData>>, index: number): void => {
  setData((prevFormData) => {
    const experiences = [...prevFormData.experiences];
    experiences.splice(index, 1);
    return {
      ...prevFormData,
      experiences,
    };
  });
};

const hasEmptyFields = (data: typeof initialData, step: number, setErrorsData: React.Dispatch<React.SetStateAction<typeof initialErrors>> ): boolean => {

  const hasEmptyFields = requiredFieldsFromSteps[step].filter((field) => {
    let specialFieldError = false;
    setErrorsData((prev) => ({ ...prev, [field]: !data[field] }));
    if (field === "roles") {
      if (data[field][0] === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }
    if (field === "steps") {
      if (data[field][0].name === "" || data[field][0].description === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }

    return !data[field] || specialFieldError;
  });

  return hasEmptyFields.length > 0;
}

export {
  initialData,
  initialErrors,
  requiredFieldsFromSteps,
  resetForm,
  handleChange,
  handleRolesChange,
  handleAddRoles,
  handleRemoveRole,
  handleStepChange,
  handleAddStep,
  handleRemoveStep,
  handleExperienceChange,
  handleAddExperience,
  handleRemoveExperience,
  hasEmptyFields
}