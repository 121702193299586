import React, { createContext, useContext, ReactNode } from "react";
import useLocalStorage from "use-local-storage";
import { getDefaultAddStepValue, initialData } from "./methodology.helper";

interface Step {
  name: string;
  description: string;
}

interface Experiences {
  article: string;
  link: string;
}

enum actionsSupported {
  add,
  change,
  remove,
}

export type handleSubmitMethodologyType = {
  field?: string;
  subField?: string;
  action?: actionsSupported;
  value?: string;
  index?: number;
  id?: string;
};

export type Methodology = {
  name: string;
  about: string;
  subjects: string;
  contents: string;
  timeToApply: string;
  participants: string;
  materials: string;
  planningTime: string;
  programmingLanguages: string;
  principles: string;
  howToPlan: string;
  howToEvaluate: string;
  roles: string[];
  faculty: string;
  student: string;
  observedMetrics: string;
  aboutSteps: string;
  steps: Step[];
  experiences: Experiences[];
  advantages: string;
  challenges: string;
  considerations: string;
  references: string;
  progressStep: number;
};

type MethodologyRepositoryContextType = {
  methodology: Methodology | null;
  actionsSupported: typeof actionsSupported;
  setter: (methodology: Methodology | null) => void;
  resetForm: () => void;
  handleSubmitMethodologyData: (data: handleSubmitMethodologyType) => void;
  updateProgressStep: (value: number) => void;
};

export const MethodologyRepositoryContext = createContext<
  MethodologyRepositoryContextType | undefined
>(undefined);

export const MethodologyRepositoryProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [methodology, setMethodology] = useLocalStorage<Methodology>(
    "methodology",
    initialData
  );

  const setter = (state) => {
    setMethodology({ ...methodology, ...state });
  };

  const resetForm = (): void => {
    setter(initialData);
  };
  const updateProgressStep = (value: number) => {
    const progressStep = value;
    setter({ progressStep });
  };

  const handleSubmitMethodologyData = ({
    field,
    action,
    value,
    index,
    id,
    subField,
  }: handleSubmitMethodologyType): void => {
    const especialFields = ["roles", "steps", "experiences"];
    const isEspecialField = especialFields.filter((f) => f === field)[0];

    if (isEspecialField) {
      if (actionsSupported[action] === "add") {
        const defaultValue = getDefaultAddStepValue(isEspecialField);
        const updateData = {
          ...methodology,
          [isEspecialField]: [...methodology[isEspecialField], defaultValue],
        };
        setter(updateData);
      }

      if (actionsSupported[action] === "remove") {
        methodology[isEspecialField].pop();
        const updateData = {
          ...methodology,
          [isEspecialField]: methodology[isEspecialField],
        };
        setter(updateData);
      }

      if (actionsSupported[action] === "change") {
        if (field !== "roles") {
          const updateValue = [...methodology[isEspecialField]];
          updateValue[index][subField] = value;

          const updateData = {
            ...methodology,
            [isEspecialField]: updateValue,
          };

          setter(updateData);
        } else {
          const updateValue = [...methodology[isEspecialField]];
          updateValue[index] = value;

          const updateData = {
            ...methodology,
            [isEspecialField]: updateValue,
          };
          setter(updateData);
        }
      }
    }

    if (!isEspecialField) {
      const updateData = {
        ...methodology,
        [id]: value,
      };
      setter(updateData);
    }
  };

  return (
    <MethodologyRepositoryContext.Provider
      value={{
        actionsSupported,
        methodology,
        setter,
        resetForm,
        handleSubmitMethodologyData,
        updateProgressStep,
      }}
    >
      {children}
    </MethodologyRepositoryContext.Provider>
  );
};

export const useMethodologyRepository =
  (): MethodologyRepositoryContextType => {
    const methodologyRepository = useContext(MethodologyRepositoryContext);
    return {
      ...methodologyRepository,
    };
  };
