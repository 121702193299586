import { oCollabbProgT } from "assets/index";
import {
  brandIfro,
  brandUfam,
  brandUnipampa,
  logoifro
} from "assets";
import {
  FooterContainer,
  LogoContainer,
  Info,
  Container,
  EmailIcon,
  Box,
  Partners
} from "./footer.styled";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Box>
        <Container>
          <LogoContainer>
            <img
              src={oCollabbProgT}
              alt="logo do collabprog"
              height="40px"
            />
          </LogoContainer>
          <Info>
            <EmailIcon />
            collabprog@ufam.edu.br
          </Info>
        </Container>

        <Partners>
          <img src={brandUfam} alt={brandIfro} height="60px" />
          <img src={logoifro} alt={logoifro} height="36px" />
          <img src={brandUnipampa} alt={brandUnipampa} height="40px" />
        </Partners>
      </Box>
      {new Date().getFullYear()} CollabProg - Todos os Direitos Reservados
    </FooterContainer>
  );
};

export default Footer;
