import { Loading, Section } from "UI/components";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useJwt } from "react-jwt";
import { useUserRepository } from "repositories";

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const { user, setter } = useUserRepository();
  const { search } = useLocation();

  const queryParams: any = queryString.parse(search);
  const dataObject = JSON.parse(queryParams?.userData);
  const decoded: any = useJwt(dataObject);

  useEffect(() => {
    if (decoded.decodedToken) {
      const {
        username,
        firstName,
        email,
        picture,
        completed,
        checked,
        accessToken,
        role
      } = decoded.decodedToken;
      setter({
        username,
        firstName,
        email,
        picture,
        completed,
        checked,
        accessToken,
        role
      });
      if (!completed) {
        navigate("/signup");
      } else {
        navigate(user?.lastPage || '/');
      }
    }
  }, [decoded, user, setter]);

  return (
    <>
      <Container>
        <Section>
          <Loading size={85} color="blue" />
        </Section>
      </Container>
    </>
  );
};

export default Signin;
