import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface DialogComponentProps {
    isOpen: boolean;
    handleClose?: () => void;
    handleConfirm: () => void;
    title: string;
    content: string;
}

const DialogComponent: React.FC<DialogComponentProps> = ({ isOpen, handleClose, handleConfirm, title, content }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
            }}
            PaperProps={{
                sx: {
                    borderRadius: '16px',
                    padding: '20px',
                }
            }}
        >
            <DialogTitle> {title} </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                <Button sx={{ marginBottom: '8px' }} variant="contained" onClick={handleConfirm}>Continuar</Button>
                <Button onClick={handleClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent;