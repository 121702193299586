import { styled } from "styled-components";

const StepHead = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: auto;
    margin-bottom: 5px;
    margin-left: 15px;
    cursor: pointer;
  }  
`;

const Step = styled.div`
    border-bottom: 1px solid #c4c4c4;
    margin: 30px 0;
    h3{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
    }
    h4 {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 24px;
    }
`;

export {
    StepHead,
    Step
};    