import React, { createContext, useContext, ReactNode } from 'react';
import useLocalStorage from 'use-local-storage';
import { initialData, Experiences, getDefaultAddStepValue } from './experience.helper';

export type handleSubmitMethodologyType = {
    field?: string;
    value?: string;
    action?: string;
    subField?: string;
    index?: number;
}

export enum actionsSupported {
    add,
    change,
    remove,
}

export type Experience = {
    title?: string;
    stars?: string;
    timeToApply?: string;
    participants?: string;
    disciplines?: string[];
    languages?: string[];
    contents?: string;
    experienceReport?: string;
    materials?: string;
    adaptedPlanDescription?: string;
    evaluate?: string;
    advantages?: string;
    challenges?: string;
    publications?: Experiences[];
    results?: string;
    considerations?: string;
};

type ExperienceRepositoryContextType = {
    experience: Experience | null;
    actionsSupported: typeof actionsSupported | null;
    setter: (experience: Experience | null) => void;
    resetForm: () => void;
    handleSubmitExperienceData: (data: handleSubmitMethodologyType) => void;
};

export const ExperienceRepositoryContext = createContext<ExperienceRepositoryContextType | undefined>(
    undefined
);

export const ExperienceRepositoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [experience, setMethodology] = useLocalStorage<Experience>("experience", initialData);

    const setter = state => {
        setMethodology({ ...experience, ...state })
    }

    const resetForm = (): void => {
        setter(initialData);
    };

    const handleSubmitExperienceData = ({ field, value, index, action, subField }: handleSubmitMethodologyType): void => {
        const especialFields = ['disciplines', 'languages', 'publications'];
        const isEspecialField = especialFields.filter(f => f === field)[0];
 
        if (isEspecialField) {
            if (action !== undefined) {
                if (actionsSupported[action] === 'add') {
                    const defaultValue = getDefaultAddStepValue(isEspecialField)
                   
                    const updateData = {
                        ...experience,
                        [isEspecialField]: [...experience[isEspecialField], defaultValue]
                    }
                    setter(updateData);
                }else if (actionsSupported[action] === 'remove') {
                    experience[isEspecialField].pop()
                    const updateData = {
                        ...experience,
                        [isEspecialField]: experience[isEspecialField]
                    }
                    setter(updateData);
                }else {
                    const updateValue = [...experience[isEspecialField]];
                    updateValue[index][subField] = value

                    const updateData = {
                        ...experience,
                        [isEspecialField]: updateValue
                    }

                    setter(updateData);
                }

            } else {
                const hasValue = experience[isEspecialField].filter(val => val === value);
                let updateValue: any;
                if(value === 'clear') {
                    updateValue = [];
                }else if (hasValue.length > 0) {
                    updateValue = experience[isEspecialField].filter(val => val !== value);
                } else {
                    updateValue = experience[isEspecialField];
                    updateValue.push(value)
                }

                const updateData = {
                    ...experience,
                    [isEspecialField]: updateValue
                }
                setter(updateData);
            }
        } else {
            const updateData = {
                ...experience,
                [field]: value
            }
            setter(updateData);
        }
    }

    return (
        <ExperienceRepositoryContext.Provider value={{ actionsSupported, experience, setter, resetForm, handleSubmitExperienceData }}>
            {children}
        </ExperienceRepositoryContext.Provider>
    );
};

export const useExperienceRepository = (): ExperienceRepositoryContextType => {
    const experienceRepository = useContext(ExperienceRepositoryContext);
    return {
        ...experienceRepository
    };
};
