import styled from "styled-components";
import { AiOutlineMail } from "react-icons/ai";

const FooterContainer = styled.footer`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  justify-content: space-around;
  background-color: #f5f5f5;
  @media (max-width: 540px) {
    span {
      font-size: 12px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      font-weight: bold;
    }

    a {
      font-size: 12px;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    font-weight: bold;
  }
  cursor: pointer;
  color: #000000;
  margin: 15px 0px;

  @media (max-width: 1240px) {
    margin:  0px;
  }
`;

const Info = styled.p`
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.75;
  font-size: 17px;
  line-height: 1.4em;

  a {
    text-decoration: none;
  }
`;

const EmailIcon = styled(AiOutlineMail)`
  font-size: 24px;
  color: #aedff7;
`;

const Container = styled.div`
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul > li {
    margin: 15px 0;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  min-width: 1220px;
  max-width: 1220px;
  margin: 0 auto;
  @media (max-width: 1240px) {
    min-width: auto;
    max-width: auto;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  ul {
    display: flex;
  }

  ul > li {
    margin: 15px;
  }

  ul > li:hover {
    text-decoration: underline;
  }

  @media (max-width: 540px) {
    // width: auto;
    justify-content: flex-start;
  }
`;

const Partners = styled.div`
    //background-color: red;
    display: flex;
    //width: 400px;
    justify-content: space-between;
    align-items: center;
    img:first-child {
      margin-left: 20px;
    }
    img {
      margin-right: 30px;
    }
`;


const CopyRight = styled.div`
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  margin: 0px auto 0px auto;
  padding: 10px 0px;
  a {
    text-decoration: underline;
  }
`;

export {
  FooterContainer,
  LogoContainer,
  Info,
  Container,
  Box,
  CopyRight,
  Right,
  EmailIcon,
  Partners
};
