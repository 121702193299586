import { Box, Divider, Typography } from "@mui/material"
import { grey, root } from "UI/theme/colors/colors";
import { useState } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { CiHome } from "react-icons/ci";
import {
    PiSignOutThin,
    PiSignInThin,
    PiUsersThin,
    PiDatabaseLight,
    PiEnvelopeThin,
    PiCodesandboxLogoThin,
    PiInfoThin,
    PiFilePlusThin,
    PiLightbulbThin
} from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useUserRepository } from "repositories";
import styled from "styled-components";
import { useLoginPopContext } from "contexts";
import { UserMode } from "./user-mode.component";
import { SideOption } from "./side-bar-option.component";

const adminRoutes = {
    dashboard: '/admin-dashboard/graphics',
    users: '/admin-dashboard/users-manager',
    methodologies: '/admin-dashboard/methodologies-manager'
}

const Img = styled.img`
    border-radius: 50px;
    width: 35px;
    margin-left: 20px;
`;

const SideBar: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [userMode, setUserMode] = useState('user');
    const { user } = useUserRepository();
    const isAdmin = user?.role === 'Admin';
    const navigate = useNavigate();
    const signOut = () => {
        window.localStorage.clear();
        window.location.reload();
    }
    const { setPopUpVisibility, handleMessage } = useLoginPopContext();

    const handlePopup = () => {
        setPopUpVisibility(true);
        handleMessage("default");
    };

    const handleOpenClose = () => {
        setIsExpanded((prev) => !prev);
    };

    const handleUserMode = (mode: string) => {
        setUserMode(mode)
        if (mode === 'admin') {
            navigate(adminRoutes.dashboard);
        } else {
            navigate('/');
        }

    }

    const isMobile = window.innerWidth < 1240;
    if (isMobile) return null;

    const MenuOptions: React.FC = () => {
        if (userMode === 'user') {
            return (
                <>
                    <SideOption isExpanded={isExpanded} Icon={CiHome} label='Home' onClick={() => navigate('/')} />
                    <SideOption isExpanded={isExpanded} Icon={PiFilePlusThin} label='Cadastrar Metodologia' onClick={() => navigate('/methodology-register')} />
                    <SideOption isExpanded={isExpanded} Icon={PiCodesandboxLogoThin} label='Metodologias' onClick={() => navigate('/methodologies')} />
                    <SideOption isExpanded={isExpanded} Icon={PiLightbulbThin} label='Recomendações' onClick={() => navigate('/recommendations')} />
                    <SideOption isExpanded={isExpanded} Icon={PiInfoThin} label='Sobre' onClick={() => navigate('/about')} />
                    <SideOption isExpanded={isExpanded} Icon={PiEnvelopeThin} label='Contatos' onClick={() => navigate('/contact')} />
                </>
            )
        }

        return (
            <>
                <SideOption isExpanded={isExpanded} Icon={PiDatabaseLight} label='Gráficos' onClick={() => navigate(adminRoutes.dashboard)} />
                <SideOption isExpanded={isExpanded} Icon={PiUsersThin} label='Usuários' onClick={() => navigate(adminRoutes.users)} />
                <SideOption isExpanded={isExpanded} Icon={PiCodesandboxLogoThin} label='Metodologias' onClick={() => navigate(adminRoutes.methodologies)} />
            </>
        );
    }


    if (!isHover && isExpanded) setIsExpanded(false);

    return (
        <Box
            bgcolor={root[0]}
            display='flex'
            borderRight={`1px solid ${grey[50]}`}
            flexDirection='column'
            height='100vh'
            position='fixed'
            zIndex={10}
            onMouseEnter={() => {
                setIsHover(true);
                setTimeout(() => {
                    setIsExpanded(true);
                }, 50)
            }}
            onMouseLeave={() => {
                setIsExpanded(false)
                setIsHover(false)
            }}
        >
            <Box>
                <SideOption isExpanded={isExpanded} Icon={FiAlignJustify} label='Menu' onClick={handleOpenClose} />
                <Box marginBottom={2} mx={2}>
                    <Divider style={{ backgroundColor: root[900] }} />
                </Box>
                {isAdmin && <UserMode isExpanded={isExpanded} userMode={userMode} handleUserMode={handleUserMode} />}

                <MenuOptions />
                <Box my={2} mx={2}>
                    <Divider style={{ backgroundColor: root[900] }} />
                </Box>
                {user?.completed && <>
                    <Box
                        display="flex"
                        justifyContent='flex-start'
                        alignItems='center'
                        my={3}
                    >
                        <Img src={user.picture} />
                        {isExpanded && <Box style={{ marginLeft: '8px' }}>
                            <Typography variant="body2">{user.firstName}</Typography>
                            <Typography sx={{ marginRight: '4px' }} variant="body2">{user.email}</Typography>
                        </Box>
                        }
                    </Box>
                </>}
                {!user?.completed && <SideOption isExpanded={isExpanded} Icon={PiSignInThin} label='Login' onClick={handlePopup} />}
                {user?.completed && <SideOption isExpanded={isExpanded} Icon={PiSignOutThin} label='Sair' onClick={signOut} />}
            </Box>

        </Box >
    );
}

export default SideBar;