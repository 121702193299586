import { Experience } from "./experience.repository";
interface Experiences {
  article: string;
  link: string;
}

const initialData = {
  title: '',
  stars: '',
  timeToApply: '',
  participants: '',
  disciplines: [],
  languages: [],
  publications: [{ article: "", link: "" }] as Experiences[],
  contents: '',
  materials: '',
  adaptedPlanDescription: '',
  evaluate: '',
  advantages: '',
  challenges: '',
  results: '',
  considerations: ''
};


const initialErrors = {
  title: false,
  stars: false,
  timeToApply: false,
  participants: false,
  disciplines: false,
  languages: false,
  contents: false,
  materials: false,
  adaptedPlanDescription: false,
  evaluate: false,
  advantages: false,
  challenges: false,
  results: false,
  considerations: false
};

const requiredFieldsFromSteps = {
  0: ["experienceReport", "timeToApply", "participants", "contents"],
  1: ["disciplines", "languages"],
  2: ["advantages", "challenges", "evaluate", "results"],
  3: ["considerations"]
}

const getDefaultAddStepValue = (field: string): any => {
  const values = {
    roles: "",
    steps: { name: "", description: "" },
    publications: { article: '', link: '' }
  }

  return values[field];
}


const checkIfHasEmptyFields = (data: Experience, step: number, setErrorsData: React.Dispatch<React.SetStateAction<typeof initialErrors>>): boolean => {

  const hasEmptyFields = requiredFieldsFromSteps[step].filter((field) => {
    let specialFieldError = false;
    setErrorsData((prev) => ({ ...prev, [field]: !data[field] }));
    if (field === "roles") {
      if (data[field][0] === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }
    if (field === "steps") {
      if (data[field][0].name === "" || data[field][0].description === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }

    return !data[field] || specialFieldError;
  });

  return hasEmptyFields.length > 0;
}

export {
  Experiences,
  initialData,
  initialErrors,
  requiredFieldsFromSteps,
  getDefaultAddStepValue,
  checkIfHasEmptyFields
}