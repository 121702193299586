import { grey } from "UI/theme/colors/colors";
import styled from "styled-components";

const Card = styled.form`
    display: flex;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    background-color: ${grey[50]};
    margin-bottom: 16px;
    div:nth-child(1) {
        display: flex;
        align-items: center;
    }
    /*&:hover {
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.315);
        transform: scale(1.01);
    }*/
    .title {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 1.2rem;
        grid-gap: 5px;
        background-color: ${grey[150]};
        p {
            font-size: 1.1rem;
        }
    }
    .content {
        display: flex;
        margin-left: 10px;
        align-items: center;
    }
    p {
        font-size: 18px;
        word-wrap: break-word;
        word-break: break-all;
    }
    h2 {
        font-family: 'Inter';
        font-weight: 700;
        max-width: 700px;
        margin: 10px;
    }
`;

export { Card };