// useUserMutations.js
import { checkUser, deleteUser, updateUser } from "api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

interface UseUserMutations {
  checkUserMutation: ReturnType<typeof useMutation>;
  deleteUserMutation: ReturnType<typeof useMutation>;
  updateUserMutation: ReturnType<typeof useMutation>;
}

interface User {
  id: number;
  username: string;
  checked: boolean;
  completed: boolean;
  createdAt: string;
  createdBy: string;
  deleted: boolean;
  email: string;
  firstName: string;
  picture: string;
  role: string;
  updatedAt: string;
  updatedBy: string;
  __v: number;
  _id: string;
}

export const useUserMutations = (): UseUserMutations => {
  const queryClient = useQueryClient();

  const updateUserMutation = useMutation<
    void,
    any,
    { userId: string; token: string; data: User }
  >(
    async ({ userId, token, data }) => {
      await updateUser(userId, token, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast("Usuário editado com sucesso!");
      },
    }
  );

  const deleteUserMutation = useMutation<
    void,
    any,
    { userId: string; token: string }
  >(
    async ({ userId, token }) => {
      await deleteUser(userId, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast("Usuário excluído com sucesso!");
      },
    }
  );

  const checkUserMutation = useMutation<
    void,
    any,
    { userId: string; token: string }
  >(
    async ({ userId, token }) => {
      const result = await checkUser(userId, token);

      return result.message;
    },
    {
      onSuccess: (message) => {
        queryClient.invalidateQueries("users");
        toast(String(message));
      },
    }
  );

  return {
    checkUserMutation,
    updateUserMutation,
    deleteUserMutation,
  };
};

export default useUserMutations;
