import styled from "styled-components";
import { InputProps } from "types/inputs";

const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #c4c4c4;
`;


const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  border-radius: 8px;

  &:focus ~ label,
  &:valid ~ label {
    transform: translateY(-25px);
    left: 0px;
    font-size: 12px;
    color: #aaa;
  }
`;


const InputContainer = styled.div<Omit<InputProps, "label" | "type">>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 0 16px;
  button {
    margin-left: auto;
  }
  p{
    color: #309630;
    width: 100%;
    text-align: end;
    cursor: pointer;
  }
  h4{
    color: red;
    width: 100%;
    text-align: start;
    cursor: pointer;
  }
  @media (max-width: 1240px) {
    margin-bottom: 20px;
    flex-direction: column;
    p{
      font-size: 14px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const CustomInputContainer = styled.div<InputProps>`
  display: flex;
  flex-direction: ${({ type }) => type === 'checkbox' ? 'row-reverse' : 'column'};
  align-itens: center;
  justify-content: start;
  min-width: ${({ type }) => type === 'checkbox' ? '150px' : 'auto'};
  flex: ${({ flex }) => flex};
  > div {
    margin-left: ${({ type }) => type === 'checkbox' ? '10px' : 'none'};    
    display: flex;
  }
`;

const InputCustom = styled.input`
  height: ${({ height }) => height};
  width: ${({ type }) => type === 'checkbox' ? '15px' : 'auto'};
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  border-radius: 8px;
  align-self: ${({ type }) => type === 'checkbox' ? 'center' : 'auto'};
  padding-left: ${({ type }) => type === 'checkbox' ? 'none' : '10px'};
  margin-bottom: ${({ type }) => type === 'checkbox' ? 'none' : '8px'};
`;

const CustomLabel = styled.label`
  pointer-events: none;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  width: "100%";
  @media (max-width: 1240px) {
    font-size: 14px;
  }
`;

const Required = styled.span`
    align-self: center;
    display: block;
    /* align-items: center; */
    justify-content: center;
    
    margin: "0 !important";
    color: red;
`;


const Textarea = styled.textarea<InputProps>`
  padding: 10px;
  height: ${({ height }) => height};
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  resize: none;
  margin-bottom: 8px;
  border-radius: 8px;
`;


export { FormContainer, CustomInputContainer, InputCustom, InputContainer, Input, Label, CustomLabel, Buttons, Required, Textarea };
