import { CustomInput, LinearStepper } from "UI/components";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import React, { useCallback, useEffect, useState } from "react";
import {
  Buttons,
  FormContainer,
} from "UI/components/shared/custom-input/custom-input.styled";


import { MethodologyRegisterModel } from "models";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useMethodologyRepository, useUserRepository } from "repositories";
import { useLoginPopContext } from "contexts";
import { initialErrors } from "./methodology-register.helper";
import { checkIfHasEmptyFields } from "repositories/methodology-repository/methodology.helper";
import { ContinueRegisterPopup } from "contexts/continue-register-popup/continue-register.component";
import { useToken } from "hooks";
import { getMethodology, updateMethodology } from "api";
import { QueryClient, useQueryClient } from "react-query";
import { Box, Typography } from "@mui/material";

const MethodologyRegister: React.FC = () => {
  const totalStep = 4;
  const {
    actionsSupported,
    methodology,
    setter,
    handleSubmitMethodologyData,
    resetForm,
    updateProgressStep,
  } = useMethodologyRepository();
  const renderContinuePopup =
    methodology?.progressStep === 0 && methodology?.name;
  const [isPopUpVisible, setPopUpVisibility2] =
    useState<any>(renderContinuePopup);
  const token = useToken();
  const queryClient = useQueryClient();
  const [step, setStep] = useState(methodology?.progressStep || 0);
  const [errorsData, setErrorsData] = useState(initialErrors);
  const { setPopUpVisibility, handleMessage } = useLoginPopContext();
  const { user } = useUserRepository();
  const navigate = useNavigate();
  const { id } = useParams();
  const fetchData = (payload: MethodologyRegisterModel) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}methodology`, payload)
      .then(() => {
        resetForm();
        navigate(`/methodologies`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (step !== totalStep) return;

    if (!user?.completed) {
      setPopUpVisibility(true);
      handleMessage("register");
    }

    if (id) {
      await updateMethodology(
        id,
        token,
        MethodologyRegisterModel.build(methodology)
      );
      queryClient.invalidateQueries("methodologies");
      navigate("/admin-dashboard/methodologies-manager");
    }
  };

  const handleStep = (value: number) => {
    updateProgressStep(value);
    setStep(value);
  };

  const getMethodologyData = useCallback(async () => {
    if (id) {
      const result = await getMethodology(id, token);
      const minMaxTimeToApply = result.timeToApply;
      const minMaxParticipants = result.participants;

      setter({
        ...result,
        participants:
          MethodologyRegisterModel.parseMinMaxToString(minMaxParticipants),
        timeToApply:
          MethodologyRegisterModel.parseMinMaxToString(minMaxTimeToApply),
      });
    }
  }, []);

  useEffect(() => {
    getMethodologyData();
  }, []);

  return (
    <>
      <Container>
        <Box>
          <Typography my={2} textAlign="center" variant="h4">
          Preencha os campos para registrar uma nova metodologia
          </Typography>
          <FormContainer>
            <LinearStepper
              handleSubmit={handleSubmit}
              stepsNumber={5}
              hasEmptyFields={() =>
                checkIfHasEmptyFields(methodology, step, setErrorsData)
              }
              step={step}
              handleStep={handleStep}
            >
              {step === 0 && (
                <>
                  <CustomInput
                    inputType="input"
                    id="name"
                    height="30px"
                    label="Nome da metodologia"
                    type="text"
                    placeholder="Base methodology- one methodology"
                    withouteffectlabel={true}
                    value={methodology.name}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "name",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    flex="1 0 100%"
                    errorMessage={errorsData["name"] ? "Campo obrigatório" : ""}
                    required
                  />
                  <CustomInput
                    inputType="input"
                    id="timeToApply"
                    height="30px"
                    label="Tempo de aplicação"
                    type="text"
                    placeholder="15-45"
                    withouteffectlabel={true}
                    value={methodology.timeToApply}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "timeToApply",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    flex="1 0 20%"
                    errorMessage={
                      errorsData["timeToApply"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="input"
                    id="participants"
                    height="30px"
                    label="Qtd. participantes"
                    type="text"
                    withouteffectlabel={true}
                    value={methodology.participants}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "participants",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="5-10"
                    flex="1 0 20%"
                    errorMessage={
                      errorsData["participants"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="textarea"
                    id="planningTime"
                    height="12px"
                    label="Tempo de planejamento"
                    withouteffectlabel={true}
                    value={methodology.planningTime}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "planningTime",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="will depend on the complexity of the content..."
                    flex="1 0 40%"
                  />

                  {methodology.roles.map((role, index) => (
                    <>
                      <CustomInput
                        inputType="input"
                        id={`role${index}`}
                        height="30px"
                        label={
                          index === 0
                            ? "Quais são os papéis do docente/estudante na metodologia"
                            : ""
                        }
                        type="text"
                        placeholder="Teacher - is the professional educator, the mediator of knowledge..."
                        withouteffectlabel={true}
                        value={role}
                        onChange={(event) =>
                          handleSubmitMethodologyData({
                            field: "roles",
                            action: actionsSupported.change,
                            value: event.target.value,
                            index,
                          })
                        }
                        flex="1 0 100%"
                        required={index === 0}
                        errorMessage={
                          errorsData["roles"] ? "Campo obrigatório" : ""
                        }
                      />
                      <Buttons>
                        {index !== 0 &&
                          index === methodology.roles.length - 1 && (
                            <h4
                              onClick={() =>
                                handleSubmitMethodologyData({
                                  field: "roles",
                                  action: actionsSupported.remove,
                                })
                              }
                            >
                              Remover papel
                            </h4>
                          )}
                        {index === methodology.roles.length - 1 && (
                          <p
                            onClick={() =>
                              handleSubmitMethodologyData({
                                field: "roles",
                                action: actionsSupported.add,
                              })
                            }
                          >
                            Adicionar papel
                          </p>
                        )}
                      </Buttons>
                    </>
                  ))}
                </>
              )}

              {step === 1 && (
                <>
                  <CustomInput
                    inputType="textarea"
                    id="about"
                    width="350px"
                    height="100px"
                    label="Descrição da Metodologia"
                    withouteffectlabel={true}
                    value={methodology.about}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "about",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="This methodology serves as a guide for completing new methodologies..."
                    flex="1 0 45%"
                    errorMessage={
                      errorsData["about"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="textarea"
                    id="subjects"
                    height="100px"
                    label="Disciplinas lecionadas"
                    withouteffectlabel={true}
                    value={methodology.subjects}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "subjects",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="Object-Oriented Programming, Introductory Programming, Programming..."
                    flex="1 0 45%"
                  />
                  <CustomInput
                    inputType="textarea"
                    id="contents"
                    height="100px"
                    label="Conteudos ou categorias relacionadas"
                    withouteffectlabel={true}
                    value={methodology.contents}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "contents",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="Computer programming, Linear data structures, Swift Language Programming Practice (SLPP)..."
                    flex="1 0 45%"
                  />

                  <CustomInput
                    inputType="textarea"
                    id="programmingLanguages"
                    height="100px"
                    label="Linguagens de programação foram utilizadas"
                    withouteffectlabel={true}
                    value={methodology.programmingLanguages}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "programmingLanguages",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="C++, Java, Python, JavaScript..."
                    flex="1 0 45%"
                  />
                  <CustomInput
                    inputType="textarea"
                    id="materials"
                    height="100px"
                    label="Material necessário"
                    withouteffectlabel={true}
                    value={methodology.materials}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "materials",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="video tutorials, App Inventor online editor, Edmodo, platfomrs online, Flash animations and video, Java Swing, Java Collections Framework and iterators, Eclipse Juno, Java vl.7, JIJnit v4,EclEmma,Jacoco, FindBugs..."
                    flex="1 0 45%"
                  />
                  <CustomInput
                    inputType="textarea"
                    id="principles"
                    height="100px"
                    label="Principios da metodologia"
                    withouteffectlabel={true}
                    value={methodology.principles}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "principles",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="Flexible Environment - mixes online and face-to-face teaching, the inverted classroom enables a series of learning formats..."
                    flex="1 0 45%"
                  />
                  <CustomInput
                    inputType="textarea"
                    id="howToPlan"
                    height="80px"
                    label="Planejamento da metodologia"
                    withouteffectlabel={true}
                    value={methodology.howToPlan}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "howToPlan",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="A space can be proposed for students to exchange information online about what they are studying, like a discussion forum. Elaborate questions on the subject to discuss in the classroom, to expand the knowledge built by students, as well as teach the application of knowledge in everyday life, to consolidate learning..."
                    flex="1 0 100%"
                  />
                </>
              )}

              {step === 2 && (
                <>
                  <CustomInput
                    inputType="textarea"
                    id="aboutSteps"
                    height="80px"
                    label="Sobre as Etapas para adoção da metodologia"
                    withouteffectlabel={true}
                    value={methodology.aboutSteps}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "aboutSteps",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="The material for studying the contents of the classes must be made available in advance so that students can study and prepare before class. The content to be studied can be presented through textbooks, slides, handouts, videos prepared by the teacher or third parties, or any combination of these...."
                    flex="1 0 100%"
                  />
                  {methodology.steps.map((stage, index) => (
                    <>
                      <CustomInput
                        inputType="input"
                        id={`stageName${index}`}
                        height="30px"
                        label={`Nome da ${index + 1}ª etapa`}
                        type="text"
                        placeholder="stage name"
                        withouteffectlabel={true}
                        value={stage.name}
                        onChange={(event) =>
                          handleSubmitMethodologyData({
                            field: "steps",
                            subField: "name",
                            action: actionsSupported.change,
                            value: event.target.value,
                            index,
                          })
                        }
                        flex="1 0 100%"
                        errorMessage={
                          errorsData["steps"] ? "Campo obrigatório" : ""
                        }
                        required
                      />
                      <CustomInput
                        inputType="textarea"
                        id={`stageDescription${index}`}
                        height="120px"
                        label={`Descrição da ${index + 1}ª etapa`}
                        withouteffectlabel={true}
                        value={stage.description}
                        placeholder="The teacher must prepare a material to support the studies of the contents must be elaborated so that it is effective in the proposal of active learning. You can use a space on the internet, which can be web pages, virtual learning platforms, such as moodle, video platforms or podcasts, to make available and share the material prepared..."
                        onChange={(event) =>
                          handleSubmitMethodologyData({
                            field: "steps",
                            subField: "description",
                            action: actionsSupported.change,
                            value: event.target.value,
                            index,
                          })
                        }
                        flex="1 0 100%"
                        errorMessage={
                          errorsData["steps"] ? "Campo obrigatório" : ""
                        }
                        required
                      />
                      <Buttons>
                        {index !== 0 &&
                          index === methodology.steps.length - 1 && (
                            <h4
                              onClick={() =>
                                handleSubmitMethodologyData({
                                  field: "steps",
                                  action: actionsSupported.remove,
                                })
                              }
                            >
                              Remover etapa
                            </h4>
                          )}
                        {index === methodology.steps.length - 1 && (
                          <p
                            onClick={() =>
                              handleSubmitMethodologyData({
                                field: "steps",
                                action: actionsSupported.add,
                              })
                            }
                          >
                            Adicionar etapa
                          </p>
                        )}
                      </Buttons>
                    </>
                  ))}

                  <CustomInput
                    inputType="textarea"
                    id="howToEvaluate"
                    height="120px"
                    label="Como avaliar a aprendizagem"
                    withouteffectlabel={true}
                    value={methodology.howToEvaluate}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "howToEvaluate",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="Assessments can be continuous, they should point out trends that can be addressed in the classroom or outside of class, and can be a questionnaire, problem solving or research..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["howToEvaluate"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                </>
              )}

              {step === 3 && (
                <>
                  <CustomInput
                    inputType="textarea"
                    id="observedMetrics"
                    height="120px"
                    label="Métricas observadas"
                    withouteffectlabel={true}
                    value={methodology.observedMetrics}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "observedMetrics",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="knowledge and skill acquisition,  motivation,  student performance..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["observedMetrics"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="textarea"
                    id="advantages"
                    height="120px"
                    label="Vantagens de adotar a metodologia"
                    withouteffectlabel={true}
                    value={methodology.advantages}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "advantages",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="It encourages interaction between students and teachers..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["advantages"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="textarea"
                    id="challenges"
                    height="120px"
                    label="Desafios de adotar a metodologia"
                    withouteffectlabel={true}
                    value={methodology.challenges}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "challenges",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="Awaken students' motivation, especially to take pre-classes..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["challenges"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                  <CustomInput
                    inputType="textarea"
                    id="considerations"
                    height="120px"
                    label="Considerações sobre a metodologia"
                    withouteffectlabel={true}
                    value={methodology.considerations}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "considerations",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="it is a methodology where direct instruction is given outside the classroom mainly through videos, and class time can be used for deeper discussions on the subject..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["considerations"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                </>
              )}

              {step === 4 && (
                <>
                  {methodology.experiences.map((experience, index) => (
                    <>
                      <CustomInput
                        inputType="input"
                        id={`experienceArticle${index}`}
                        height="30px"
                        label={`Nome do artigo`}
                        type="text"
                        placeholder="Applying PBL in Teaching Programming: na Experience Report "
                        withouteffectlabel={true}
                        value={experience.article}
                        onChange={(event) =>
                          handleSubmitMethodologyData({
                            field: "experiences",
                            subField: "article",
                            action: actionsSupported.change,
                            value: event.target.value,
                            index,
                          })
                        }
                        flex="1 0 100%"
                      />
                      <CustomInput
                        inputType="textarea"
                        id={`experienceLink${index}`}
                        height="30px"
                        label={`Link do artigo`}
                        withouteffectlabel={true}
                        value={experience.link}
                        placeholder="https://ieeexplore.ieee.org/abstract/document/8658978"
                        onChange={(event) =>
                          handleSubmitMethodologyData({
                            field: "experiences",
                            subField: "link",
                            action: actionsSupported.change,
                            value: event.target.value,
                            index,
                          })
                        }
                        flex="1 0 100%"
                      />
                      <Buttons>
                        {index !== 0 &&
                          index === methodology.experiences.length - 1 && (
                            <h4
                              onClick={() =>
                                handleSubmitMethodologyData({
                                  field: "experiences",
                                  action: actionsSupported.remove,
                                })
                              }
                            >
                              Remover experiencia
                            </h4>
                          )}
                        {index === methodology.experiences.length - 1 && (
                          <p
                            onClick={() =>
                              handleSubmitMethodologyData({
                                field: "experiences",
                                action: actionsSupported.add,
                              })
                            }
                          >
                            Adicionar experiencia
                          </p>
                        )}
                      </Buttons>
                    </>
                  ))}
                  <CustomInput
                    inputType="textarea"
                    id="references"
                    height="120px"
                    label="Referências"
                    withouteffectlabel={true}
                    value={methodology.references}
                    onChange={(e) =>
                      handleSubmitMethodologyData({
                        field: "references",
                        id: e.target.id,
                        action: actionsSupported.change,
                        value: e.target.value,
                      })
                    }
                    placeholder="[1] CAO, Lijuan; GRABCHAK, Michael. Interactive preparatory work in a flipped programming course. In: Proceedings of the ACM Conference on Global Computing Education. 2019. p. 229-235. ,[2]  DURAK, Hatice Yildiz. Modeling different variables in learning basic concepts of programming in flipped classrooms. Journal of Educational Computing Research, v. 58, n. 1, p. 160-199, 2020..."
                    flex="1 0 100%"
                    errorMessage={
                      errorsData["references"] ? "Campo obrigatório" : ""
                    }
                    required
                  />
                </>
              )}
            </LinearStepper>
          </FormContainer>
        </Box>
      </Container>
      {isPopUpVisible && (
        <ContinueRegisterPopup
          eraserData={resetForm}
          closePopup={() => setPopUpVisibility2(false)}
        />
      )}
    </>
  );
};

export default MethodologyRegister;
