import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { Star, Starbox } from './star-rating.styled';

interface StarRating {
  ratingSelected: number;
  setRatingSelected: any
}

interface StarRatingProps {
  stars: number;
}

export const RenderStarRating: React.FC<StarRatingProps> = ({ stars }) => {
  return (
    <Starbox>
      {[1, 2, 3, 4, 5].map((value) => (
        <span>
          {value <= stars ? <AiFillStar size={25} color='#f1af09' /> : <AiOutlineStar size={25} />}
        </span>
      ))}
    </Starbox>
  );
}


const StarRating: React.FC<StarRating> = ({ ratingSelected, setRatingSelected }) => {

  return (
    <Starbox>
      {[1, 2, 3, 4, 5].map((value) => (
        <Star key={value} onClick={() => setRatingSelected(value)}>
          {value <= ratingSelected ? <AiFillStar size={25} color='#f1af09' /> : <AiOutlineStar size={25} />}
        </Star>
      ))
      }
    </Starbox >
  );
};

export default StarRating;

