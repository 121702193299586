import { checkRole } from "api";
import { useToken } from "hooks";
import React, { useEffect, useCallback, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../loading/loading.component";
import { useUserRepository } from "repositories";

const ProtectedRouteGuard: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { user } = useUserRepository();
  const token = user?.accessToken;
  const [role, setRole] = React.useState<string | null>(null);

  const fetchRole = useCallback(async () => {
    try {
      if (!token) {
        navigate("/");
        return;
      }

      const userRole = await checkRole(token);
      setRole(userRole.user.role);
    } catch (error) {
      console.error("Error checking role:", error);
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  const loadingComponent = useMemo(
    () => <Loading size={50} color={"#777777"} />,
    []
  );

  if (role === null) {
    return loadingComponent;
  }

  return role?.toLowerCase()  === "admin" ? <>{children}</> : <Navigate to="/" />;
};

export default ProtectedRouteGuard;
