import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

async function setAuthHeader(token: string): Promise<{
  headers: {
    Authorization: string;
  };
}> {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export { instance as api, setAuthHeader };
