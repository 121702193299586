import StarRating, { RenderStarRating } from "UI/components/star-rating/star-rating.component";
import { Card } from "./card-item.styled";
interface CardProps { 
    user: string; 
    stars: number; 
    evaluation: string; 
    onClick?: () => void 
}

const CardItem: React.FC<CardProps> = ({ user, stars, evaluation, onClick }) => {
    return (
        <>
            <Card onClick={onClick}>
                <div className="title">
                    <h2>{user}</h2>
                    <RenderStarRating stars={stars}/>
                </div>
                <div className="content">
                    <p>{evaluation}</p>
                </div>                      
            </Card>
        </>
    );
}

export default CardItem;