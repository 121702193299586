import React from "react";
import { ButtonsGrid, PopupContent, Overlay } from "./login.styled";
import { Button } from "UI/components";
import { googleIcon } from "assets/index";
import { red, root } from "UI/theme/colors/colors";
import { useUserRepository } from "repositories";

interface LoginPopupProps {
  closePopup: () => void;
  message: string;
}

export const LoginPopup: React.FC<LoginPopupProps> = ({ closePopup, message }) => {
  const { setter } = useUserRepository();

  const handleLoginWithGoogle = async () => {
    setter({ lastPage: window.location.pathname });
    window.location.href = `${process.env.REACT_APP_API_URL}login-google`;
  };

  return (
    <>
      <Overlay />
      <PopupContent>
        <h3>{message} faça login com a sua conta do google</h3>
        <ButtonsGrid>
          <Button onclick={handleLoginWithGoogle} bgcolor={root[0]} color={root[900]} width={"220px"} height={"40px"}>
            <div>
              <img src={googleIcon} alt={googleIcon} width="20px" />
              <p>Login</p>
            </div>
          </Button>
          <Button bgcolor={red[50]} color={root[0]} width={"220px"} height={"40px"} onclick={closePopup}><p>Cancelar</p></Button>
        </ButtonsGrid>
      </PopupContent>
    </>
  );
}

export default { LoginPopup };