import { CustomInput, Loading, Section } from "UI/components";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserRepository } from "repositories";
import { User } from "repositories/user-repository/user.repository";
import { signUp } from "api/auth-api/auth.api";
import CustomInputAutoComplete from "UI/components/shared/custom-input-auto-complete/custom-input-auto-complete.component";
import { specializations, subjects, universities } from "data";
import { Box, FormLabel } from "@mui/material";
import { root } from "UI/theme/colors/colors";
import CustomButton from "UI/components/shared/custom-button/custom-button.component";
import { getContentType } from "api/contents-api/contents.api";

const initialData = {
  university: "",
  discipline: "",
  specialization: "",
  role: "",
  otherUniversity: false,
  otherDiscipline: false,
};

const initialErrors = {
  university: false,
  discipline: false,
  specialization: false,
  role: false,
  otherUniversity: false,
  otherDiscipline: false,
};

const Signup: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [errorsData, setErrorsData] = useState(initialErrors);
  const { user, setter } = useUserRepository();
  const [contents, setContents] = useState([]);
  const getMethodologyName = async () => {
    const result = await getContentType("subject", user.accessToken);
    const parsedValue = result.map((item: any) => {
      return {
        label: item.name,
        value: item.name
      }
    })
    parsedValue.push({ label: 'Outro', value: 'Outro' });
    setContents(parsedValue);
  }

  useEffect(() => {
    getMethodologyName();
  }, [])

  const [otherValues, setOtherValues] = useState({ discipline: '', university: '' });
  const navigate = useNavigate();

  const handleChange = (
    id?: string,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {

    if (event === 'Outro') {
      if (id === 'university') {
        setData((prevFormData) => ({ ...prevFormData, otherUniversity: true }));
      } else {
        setData((prevFormData) => ({ ...prevFormData, otherDiscipline: true }));
      }
    } else if (!event) {
      if (id === 'university') {
        setData((prevFormData) => ({ ...prevFormData, otherUniversity: false }));
      }
      if (id === 'discipline') {
        setData((prevFormData) => ({ ...prevFormData, otherDiscipline: false }));
      }
    }
    setData((prevFormData) => ({
      ...prevFormData,
      [id]: event,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const requiredFields = ["role","university", "discipline", "specialization"];
    const hasEmptyFields = requiredFields.filter((field) => {
      if (field === 'discipline' && data.otherDiscipline && !otherValues.discipline) {
        setErrorsData((prev) => ({ ...prev, otherDiscipline: true }));
        return true;
      } else if (field === 'university' && data.otherUniversity && !otherValues.university) {
        setErrorsData((prev) => ({ ...prev, otherUniversity: true }));
        return true;
      } else {
        return !data[field];
      }
    });

    if (hasEmptyFields.length > 0) return;
    else setErrorsData(initialErrors);

    if(data.otherDiscipline) {
      data.discipline = otherValues.discipline;
    }

    if(data.otherUniversity) {
      data.university = otherValues.university;
    }

    setIsLoading(true);
    try {
      const response = await signUp(user.accessToken, data);
      const userInfo: User = response;
      setter({ ...userInfo });
      navigate(user.lastPage);
    } catch (e) {
      console.error("onSubmit error:: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtherValues = (id: string, value: string) => {
    setOtherValues(prev => ({ ...prev, [id]: value }));
  }

  const roleTypes = [
    { label: "Aluno", value: "Common" },
    { label: "Professor", value: "Teacher" },
  ]

  const renderInputs = () => {
    return (
      <>
        <CustomInputAutoComplete
          description="Tipo de suuário"
          handleValues={(val) => handleChange('role', val)}
          optionsValues={roleTypes}
          acceptsMultipleChoices={false}
          title="Tipo de Usuário"
          disabledCloseOnSelect
        />
        <CustomInputAutoComplete
          description="Especialização"
          handleValues={(val) => handleChange('specialization', val)}
          optionsValues={specializations}
          acceptsMultipleChoices={false}
          title="Especialização"
          disabledCloseOnSelect
        />
        <Box>
          <CustomInputAutoComplete
            description="Universidade"
            handleValues={(val) => handleChange('university', val)}
            optionsValues={universities}
            acceptsMultipleChoices={false}
            title="Universidade"
            disabledCloseOnSelect
          />

          {data.otherUniversity && <CustomInput
            inputType="input"
            id='university'
            placeholder="Digite a universidade"
            withouteffectlabel={true}
            value={otherValues.university}
            height="30px"
            onChange={(e) => handleOtherValues('university', e.target.value)}
            errorMessage={errorsData["otherUniversity"] ? "Campo obrigatório" : ""}
          />}
        </Box>
        <Box>
          <CustomInputAutoComplete
            description="Disciplina"
            handleValues={(val) => handleChange('discipline', val)}
            optionsValues={contents}
            acceptsMultipleChoices={false}
            title="Disciplina ou Curso"
            disabledCloseOnSelect
          />

          {data.otherDiscipline && <CustomInput
            inputType="input"
            id='discipline'
            placeholder="Digite a disciplina ou curso"
            withouteffectlabel={true}
            value={otherValues.discipline}
            height="30px"
            onChange={(e) => handleOtherValues('discipline', e.target.value)}
            errorMessage={errorsData["otherDiscipline"] ? "Campo obrigatório" : ""}
          />}
        </Box>
      </>
    )
  };

  return (
    <>
      <Container>
        {isLoading && (
          <Section>
            <Loading size={85} color="blue" />
          </Section>
        )}
        {!isLoading && (
          <Box
            display='flex'
            flexWrap='wrap'
            width='100%'
            maxWidth='1240px'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
            margin='40px auto'
            padding='0 54px'
          >
            <FormLabel sx={{ fontSize: '36px', fontWeight: 500, color: root[900], marginBottom: '16px' }}>Completar cadastro</FormLabel>
            <Box display='flex' width='100%' maxWidth='1000px' flexWrap='wrap' justifyContent='space-between'>
              {renderInputs()}
              <CustomButton label="Finalizar Cadastro" variant="contained" onclick={handleSubmit} />
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Signup;
