import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useToken, useMethodologiesMutations } from "hooks";
import ReactLoading from "react-loading";
import { DeleteButton, EditButton } from "../shared/button/button.styled";
import MethodologyViewModalForm from "../methodology-view/methodology-view.component";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import { useNavigate } from "react-router-dom";

interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

type ItemProps = Methodology;

interface TableActions<T> {
  onEdit: (item: T) => void;
  onDelete: (itemId: string) => void;
}

export interface RenderConfig {
  fields: string[] | Record<string, any>;
}

interface TableRowProps<T> extends TableActions<T> {
  item: T;
  renderConfig: RenderConfig;
}

const TableRow: React.FC<TableRowProps<ItemProps>> = ({
  item,
  onEdit,
  onDelete,
  renderConfig,
}) => {
  const { updateMethodologyMutation } = useMethodologiesMutations();
  const [isLoading, setIsLoading] = useState(false);
  const [methodologyEdit, setMethodologyEdit] = useState(false);
  const [methodologyView, setMethodologyView] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const token = useToken();
  const navigate = useNavigate();

  const handleValidateMethodology = async () => {
    setIsLoading(true);
    try {
      await updateMethodologyMutation.mutateAsync({
        methodologyId: item._id,
        data: {
          checked: !item.checked,
        },
        token,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMethodologyEdit = () => {
    navigate(`edit-methodology/${item._id}`);
    // setMethodologyEdit(!methodologyEdit);
  };

  const handleMethodologyView = () => {
    setMethodologyView(!methodologyView);
  };

  const handleDeleteMethodology = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setConfirmationModalOpen(false);
    onDelete(item._id);
  };

  const handleCancelDelete = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <>
      {methodologyView && (
        <MethodologyViewModalForm
          item={item as Methodology}
          handleUserView={handleMethodologyView}
          type={"methodologies"}
        />
      )}
      {isLoading ? (
        <tr>
          <td colSpan={6}>
            <div style={{ textAlign: "center" }}>
              <ReactLoading
                type={"spinningBubbles"}
                color={"#007bff"}
                height={30}
                width={30}
              />
            </div>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <input
              type="checkbox"
              checked={item.checked}
              onChange={handleValidateMethodology}
            />
          </td>
          <td>{(item as Methodology).name}</td>
          <td>
            <AiFillEye
              onClick={handleMethodologyView}
              fontSize={30}
              cursor={"pointer"}
            />
          </td>
          <td
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <EditButton onClick={handleMethodologyEdit}>Editar</EditButton>
            <DeleteButton onClick={handleDeleteMethodology}>
              Excluir
            </DeleteButton>
          </td>
        </tr>
      )}

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default TableRow;
