import styled from "styled-components";
import { ButtonProps } from "./button.types";

const CustomButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color ?? "#ffffff"} !important;
  width: ${({ width }) => width ?? "200px"} !important;
  height: ${({ height }) => height ?? "59px"};
  background: ${({ bgcolor }) => bgcolor ?? "#308099"};
  border-radius: ${({ radius }) => radius ?? "12px"};
  cursor: pointer;
  &:hover {
    background-color: ${({ hover, bgcolor }) =>
      hover ? "#3388a1" : bgcolor ?? "#308099"};
  }
  outline: none;
  border: none;
  font-size: 16px;
  @media (max-width: 1240px) {
    width: 120px !important;
    font-size: 12px;
    height: 30px;
  }
`;

const EditButton = styled.button`
  background-color: #007bff; /* Azul */
  color: #fff; /* Texto branco */
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3; /* Azul mais escuro no hover */
  }
`;

const DeleteButton = styled.button`
  background-color: #dc3545; /* Vermelho */
  color: #fff; /* Texto branco */
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c82333; /* Vermelho mais escuro no hover */
  }
`;

export { CustomButton, EditButton, DeleteButton };
