import { Loading, MethodologyCard } from "UI/components";
import { Box, Typography } from "@mui/material";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethodologies } from "api";
import { root } from "UI/theme/colors/colors";

const Methodologies: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMethodologies("");
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleClick = (id: string) => {
    navigate(`/methodology/${id}`);
  };

  return (
    <Container>
      <Box bgcolor={root[0]}>
        {loading ? (
          <Loading size={85} color="blue" />
        ) : (
          <Box>
            <Typography my={2} textAlign="center" variant="h4">
              Metodologias
            </Typography>
            {data.map(
              ({ _id, name, about, image, checked }, index: number) =>
                checked && (
                  <MethodologyCard
                    key={_id}
                    onClick={() => handleClick(_id)}
                    name={name}
                    about={about}
                    image={image}
                    index={index + 1}
                  />
                )
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Methodologies;
