import React from "react";
import { Title } from "./login.styled";
import { useLoginPopContext } from "contexts";

export const PopupLogin: React.FC = () => {
  const { setPopUpVisibility, handleMessage } = useLoginPopContext();

  const handlePopup = () => {
    setPopUpVisibility(true);
    handleMessage("login");
  };

  return <Title onClick={handlePopup}>Login</Title>;
};
