import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { TopBar } from "UI/components";

const DashboardAdmin: React.FC = () => {
  const [fixedSideBar, setFixedSideBar] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function toggleFixedSidebar() {
    setFixedSideBar(!fixedSideBar);
  }

  return (
    <>
      <TopBar
        title={location.pathname}
        toggleSidebar={toggleSidebar}
        toggleFixedSidebar={toggleFixedSidebar}
      />
      <Outlet />
    </>
  );
};

export default DashboardAdmin;
