import React from "react";
import { ErrorMessage } from "../pages-styles/pages-styles";
import {
  CustomLabel,
  InputCustom,
  CustomInputContainer,
  Required,
  Textarea,
} from "./custom-input.styled";
import { InputProps } from "types/inputs";

const CustomInput: React.FC<InputProps> = ({
  error,
  errorMessage,
  flex,
  height,
  id,
  label,
  onChange,
  placeholder,
  required,
  type,
  value,
  width,
  checked,
  inputType,
}) => {

  if (inputType === "input") {
    return (
      <CustomInputContainer type={type} flex={flex}>
        <div>
          <CustomLabel htmlFor={label}>{label}</CustomLabel>{" "}
          {required && <Required>*</Required>}
        </div>
        <InputCustom
          checked={type ===  'checkbox' ? checked : undefined}
          height={height}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          type={type}
          id={id}
        />
        <ErrorMessage>{error?.message || errorMessage}</ErrorMessage>
      </CustomInputContainer>
    );
  }

  return (
    <CustomInputContainer flex={flex}>
      <div>
        <CustomLabel htmlFor={label}>{label}</CustomLabel>{" "}
        {required && <Required>*</Required>}
      </div>
      <Textarea
        height={height}
        width={width}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id={id}
      />
      <ErrorMessage>{error?.message || errorMessage}</ErrorMessage>
    </CustomInputContainer>
  );
};

export default CustomInput;
