import { api, setAuthHeader } from "../api";

interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

async function getMethodologies(token: string): Promise<any> {
  const response = await api.get("methodology", await setAuthHeader(token));

  return response.data.list;
}

async function deleteMethodology(id: string, token: string): Promise<any> {
  const response = await api.delete(
    `methodology/${id}`,
    await setAuthHeader(token)
  );

  return response.data;
}

async function getMethodology(id: string, token: string): Promise<any> {
  const response = await api.get(
    `methodology/${id}`,
    await setAuthHeader(token)
  );

  return response.data;
}

async function updateMethodology(
  id: string,
  token: string,
  data: unknown
): Promise<any> {
  const response = await api.patch(
    `methodology/${id}`,
    data,
    await setAuthHeader(token)
  );

  return response.data;
}

export {
  getMethodologies,
  deleteMethodology,
  getMethodology,
  updateMethodology,
};
