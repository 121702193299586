import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LoginPopup } from './login-popup.component';
import { messages } from './login-popup-helper';

interface LoggiPopupContextProps {
    setPopUpVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    handleMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const LoggiPopupContext = createContext<LoggiPopupContextProps | undefined>(undefined);

export const LoginPopupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPopUpVisible, setPopUpVisibility] = useState(false);
    const [message, setMessage] = useState(messages['login']);
    const closePopup = () => {
        setPopUpVisibility(false)
    }
    const handleMessage = (type: string) => {
        setMessage(messages[type]);
    }
    return (
        <LoggiPopupContext.Provider value={{ setPopUpVisibility, handleMessage }}>
            {children}
            {isPopUpVisible && <LoginPopup closePopup={closePopup} message={message} />}
        </LoggiPopupContext.Provider>
    );
};

LoginPopupProvider.propTypes = { children: PropTypes.node.isRequired };

export const useLoginPopContext = (): { setPopUpVisibility: React.Dispatch<React.SetStateAction<boolean>>,  handleMessage: React.Dispatch<React.SetStateAction<string>>}=> {
    const { setPopUpVisibility, handleMessage } = useContext(LoggiPopupContext);

    return { setPopUpVisibility, handleMessage };
};
