import { useState } from "react";
import { styled } from "styled-components";
import { DeleteButton } from "../shared/button/button.styled";
import { FormContainer } from "../shared/custom-input/custom-input.styled";

interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

const MethodologyViewModalForm: React.FC<{
  item: Methodology;
  handleUserView: () => void;
  type: string;
}> = ({ item, handleUserView }) => {
  const [dataInfo, setDataInfo] = useState<Methodology>(item);

  const renderInputs = () => {
    const userFields = Object.keys(dataInfo);

    return userFields.map((field) => {
      const value = dataInfo[field];

      if (field === "timeToApply" || field === "participants") {
        return (
          <>
            <CustomLabel htmlFor="role">{field}</CustomLabel>
            <CustomViewArea
              key={field}
              id={field}
              height="150px"
              value={`Min: ${value.min}, Max: ${value.max}`}
              disabled={true}
            />
          </>
        );
      }
      if (field === "steps") {
        return (
          <>
            <CustomLabel htmlFor={"role"}>{field}</CustomLabel>
            <CustomViewArea
              id={field}
              height="150px"
              value={value
                .map(
                  (step: { name: string; description: string }) =>
                    `${step.name}: ${step.description}\n`
                )
                .join("\n")}
              disabled={true}
            />
          </>
        );
      }
      if (field === "experiences") {
        return (
          <>
            <CustomLabel htmlFor={"role"}>{field}</CustomLabel>
            <CustomViewArea
              id={field}
              height="150px"
              value={value
                .map(
                  (value: { article: string; link: string }) =>
                    `Article: ${value.article}, Link: ${value.link}\n`
                )
                .join("\n")}
              disabled={true}
            />
          </>
        );
      }
      return (
        <>
          <CustomLabel htmlFor="role">{field}</CustomLabel>
          <CustomViewArea
            key={field}
            id={field}
            height="150px"
            value={dataInfo[field]}
            disabled={true}
          />
        </>
      );
    });
  };

  return (
    <ModalOverlay>
      <RelativeContainer flex={""}>
        <Modal>
          <FormContainerColumn onSubmit={handleUserView}>
            <div style={{ display: "flex", width: "100%" }}>
              <DeleteButtonRelative>X</DeleteButtonRelative>
            </div>
            {renderInputs()}
            <div style={{ display: "flex", width: "100%" }}>
              <DeleteButtonRelative>X</DeleteButtonRelative>
            </div>
          </FormContainerColumn>
        </Modal>
      </RelativeContainer>
    </ModalOverlay>
  );
};

const FormContainerColumn = styled(FormContainer)`
  flex-direction: column;
  flex-direction: column;
  position: sticky;
  bottom: 0;
`;

const RelativeContainer = styled.div<{ flex }>`
  position: relative;
  ${({ flex }) =>
    flex?.length
      ? "display:flex; align-items: center; justify-content: center;"
      : ""}
  width: 100%;
  height: 100%;
`;

const DeleteButtonRelative = styled(DeleteButton)`
d  max-width: 150px;
  width: 100%;
  height: 40px;
  top: 207px;
  left: calc(100% - 935px);
  max-width: 50px;
`;

const CustomLabel = styled.label`
  pointer-events: none;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  @media (max-width: 1240px) {
    font-size: 14px;
  }
`;

const CustomViewArea = styled.textarea<{ height }>`
  max-width: 100%;
  height: ${({ height }) => height};
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  line-height: 20px;
`;
const Modal = styled.div`
  position: fixed;
  z-index: 5;
  width: 85%;
  max-width: 534px;
  height: 50%;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  padding: 30px;

  overflow: auto; /* Adicione overflow: auto para permitir rolagem */
  scrollbar-width: thin; /* Para navegadores que suportam a propriedade scrollbar-width (Firefox) */
  -ms-overflow-style: none; /* Para navegadores que suportam a propriedade -ms-overflow-style (Edge) */

  &::-webkit-scrollbar {
    width: 5px; /* Largura da barra de rolagem no WebKit */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do polegar da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Cor da trilha da barra de rolagem */
  }
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;
export default MethodologyViewModalForm;
