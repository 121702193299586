import React from "react";
import { Box, Stepper, Step, StepLabel, Button } from "@mui/material";

interface LinearStepperProps {
  children: React.ReactNode | any;
  step: number;
  stepsNumber?: number;
  handleStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  hasEmptyFields: () => boolean;
}

const LinearStepper: React.FC<LinearStepperProps> = ({
  handleSubmit,
  hasEmptyFields,
  children,
  step,
  stepsNumber,
  handleStep,
}) => {
  const steps = Array.from({ length: stepsNumber }, (_, i) => i);
  const lastStep = step === steps.length - 1;
  const handleNext = () => {
    const hasError = hasEmptyFields();
    if (hasError) return;
    if (lastStep) {
      return handleSubmit();
    }
    handleStep(step + 1);
  };

  const handleBack = () => {
    handleStep(step - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper sx={{ marginBottom: "24px" }} activeStep={step}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          boxSizing: "borderBox",
          gap: "0 16px",
        }}
      >
        {children}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {step !== 0 && (
          <Button color="inherit" onClick={handleBack}>
            Voltar
          </Button>
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext}>
          {lastStep ? "Finalizar" : "Próximo"}
        </Button>
      </Box>
    </Box>
  );
};

export default LinearStepper;
