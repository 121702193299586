import Button from "UI/components/shared/button/button.component";
import CardItem from "UI/components/experience-components/card-item/card-item.component";
import { Box } from "./experience-list.styled";
import { Box as BoxMui, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { CustomInput } from "UI/components";
import StarRating from "UI/components/star-rating/star-rating.component";
import { useLoginPopContext } from "contexts";
import { useFeedbackRepository, useUserRepository } from "repositories";
import { getFeedbacks, postFeedback } from "api/feedback-api/feedback-api";
import DialogComponent from "UI/components/dialog/dialog.component";

const ExperienceList: React.FC<{ name: string }> = ({ name }) => {
    const { feedback, setter } = useFeedbackRepository();
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sendNewFeedback, setSendNewFeedback] = useState<boolean>(false);
    const [comment, setComment] = useState<any>(feedback?.comment || '');
    const [ratingSelected, setRatingSelected] = useState(feedback.stars | 3);
    const { setPopUpVisibility, handleMessage } = useLoginPopContext();
    const [isOpenMethodologyFeedbackDialog, setIsOpenMethodologyFeedbackDialog] = useState(false);
    const { user } = useUserRepository();
    const { id } = useParams();
    const navigate = useNavigate();

    const getAllFeedbacks = useCallback(async () => {
        if (sendNewFeedback || loading) {
            setSendNewFeedback(false)
            try {
                const response = await getFeedbacks(id);
                setData(response);
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false);
            }
        }
    }, [id, loading, sendNewFeedback]);

    useEffect(() => {
        getAllFeedbacks();
    }, [getAllFeedbacks]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;
        setComment(value);
    };

    const postFeedBack = async () => {
        if (!user?.completed) {
            setter({
                idMethodology: id,
                stars: ratingSelected,
                comment
            })
            setPopUpVisibility(true);
            return handleMessage('feedback')
        }

        setter({
            idMethodology: id,
            stars: ratingSelected,
            nameUser: user.firstName,
            comment
        });

        const data = {
            idMethodology: id,
            stars: ratingSelected,
            nameUser: user.firstName,
            comment
        };
        setSendNewFeedback(true);

        try {
            await postFeedback(user.accessToken, data);
            setter({});
            setComment('');
            if (user.role === 'Teacher') setIsOpenMethodologyFeedbackDialog(true);
            setLoading(true)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Box>
                <Typography my={2} textAlign="center" variant="h5">
                    {`Avaliações e Experiências: ${name}`}
                </Typography>
                {data.map(feedback => <CardItem user={feedback.nameUser} onClick={() => console.log('clicado')} stars={feedback.stars} evaluation={feedback.comment} />)}
            </Box>

            <BoxMui my={3} >
                <h4 style={{ marginBottom: '12px', fontSize: '25px' }}>Deixe sua avaliação</h4>
                <BoxMui width='80%'>
                    <BoxMui width='160px' alignItems='center' display='flex'>
                        <StarRating ratingSelected={ratingSelected} setRatingSelected={setRatingSelected} />
                        <span style={{ color: 'red' }}>*</span>
                    </BoxMui>
                </BoxMui>
                <CustomInput
                    inline
                    inputType="input"
                    label="Insira seu comentário"
                    id="comment"
                    height="30px"
                    type="text"
                    flex="1 0 70%"
                    value={comment}
                    onChange={handleChange}
                />
                <Button
                    color="#ffffff"
                    width="233px"
                    height="35px"
                    radius="16px"
                    bgcolor="#308099"
                    type="submit"
                    onclick={postFeedBack}
                >
                    Avaliar
                </Button>
            </BoxMui>

            <DialogComponent
                isOpen={isOpenMethodologyFeedbackDialog}
                handleClose={() => setIsOpenMethodologyFeedbackDialog(false)}
                handleConfirm={() => navigate(`/experience-form/${id}`)}
                title="Que tal, avaliar a metodologia detalhadamente?"
                content="Avalie a metodologia detalhadamente e nos ajude a melhorar a experiência de ensino!"
            />
        </>
    );
}

export default ExperienceList;
