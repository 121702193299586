import styled from "styled-components";

const Starbox = styled.div`
    display: flex;
    height: 20px;
`;

const Star = styled.span`
 &:hover {
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.315);
        transform: scale(1.01);
    }
`

export {
    Starbox,
    Star
}