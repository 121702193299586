import {
  Methodology,
  handleSubmitMethodologyType,
} from "./methodology.repository";

interface Step {
  name: string;
  description: string;
}

interface Experiences {
  article: string;
  link: string;
}

const initialData = {
  name: "",
  about: "",
  subjects: "",
  contents: "",
  timeToApply: "",
  participants: "",
  materials: "",
  planningTime: "",
  programmingLanguages: "",
  principles: "",
  howToPlan: "",
  howToEvaluate: "",
  roles: [""],
  faculty: "",
  student: "",
  observedMetrics: "",
  aboutSteps: "",
  steps: [{ name: "", description: "" }] as Step[],
  experiences: [{ article: "", link: "" }] as Experiences[],
  advantages: "",
  challenges: "",
  considerations: "",
  references: "",
  progressStep: 0,
};

const initialErrors = {
  name: false,
  about: false,
  timeToApply: false,
  participants: false,
  howToEvaluate: false,
  roles: false,
  observedMetrics: false,
  steps: false,
  advantages: false,
  challenges: false,
  considerations: false,
  references: false,
};

const requiredFieldsFromSteps = {
  0: ["name", "timeToApply", "participants", "roles"],
  1: ["about"],
  2: ["steps", "howToEvaluate"],
  3: ["observedMetrics", "advantages", "challenges", "considerations"],
  4: ["references"],
};

const getDefaultAddStepValue = (field: string): any => {
  const values = {
    roles: "",
    steps: { name: "", description: "" },
    experiences: { article: "", link: "" },
  };

  return values[field];
};

const checkIfHasEmptyFields = (
  data: Methodology,
  step: number,
  setErrorsData: React.Dispatch<React.SetStateAction<typeof initialErrors>>
): boolean => {
  const hasEmptyFields = requiredFieldsFromSteps[step].filter((field) => {
    let specialFieldError = false;
    setErrorsData((prev) => ({ ...prev, [field]: !data[field] }));
    if (field === "roles") {
      if (data[field][0] === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }
    if (field === "steps") {
      if (data[field][0].name === "" || data[field][0].description === "") {
        specialFieldError = true;
        setErrorsData((prev) => ({ ...prev, [field]: true }));
      }
    }

    return !data[field] || specialFieldError;
  });

  return hasEmptyFields.length > 0;
};

export {
  initialData,
  initialErrors,
  requiredFieldsFromSteps,
  getDefaultAddStepValue,
  checkIfHasEmptyFields,
};
