import styled from "styled-components";

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 260px;
  width: 100vw !important;
  @media (max-width: 540px) {
    width: 100%;
    height: 150px;
  }
`;

const Slide = styled.div`
  width: 120px;
  height: 120px;

  @media (max-width: 540px) {
    width: 61px;
    height: 50px;
  }
`;

const Img = styled.img`
  width: 300px;
  height: 200;
  @media (max-width: 540px) {
    width: 100px !important;
    height: 60px;
  }
`;

const CarouselContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 540px) {
    width: 100%;
    height: 180px;
  }
`;

export { CarouselContainer, CarouselContent, Slide, Img };
