interface MinMax {
    min: number;
    max: number;
}

interface Experiences {
    article: string;
    link: string;
}

interface otherValues {
    disciplines?: string;
    languages?: string;
}

interface dataModel {
    idMethodology?: string;
    title?: string;
    stars?: string;
    timeToApply?: string;
    participants?: string;
    disciplines?: string[];
    languages?: string[];
    contents?: string;
    experienceReport?: string;
    materials?: string;
    adaptedPlanDescription?: string;
    evaluate?: string;
    advantages?: string;
    challenges?: string;
    publications?: Experiences[];
    results?: string;
    considerations?: string;
}

export default class ExperienceRegisterModel {
    private idMethodology: string;
    private timeToApply: MinMax;
    private participants: MinMax;
    private disciplines: string[];
    private languages: string[];
    private contents: string;
    private experienceReport: string;
    private materials: string;
    private adaptedPlanDescription: string;
    private evaluate: string;
    private advantages: string;
    private challenges: string;
    private publications: { article: string, link: string }[];
    private results: string;
    private considerations: string;

    constructor(data: dataModel, otherValues: otherValues) {
        this.idMethodology = data.idMethodology;
        this.timeToApply = this.parseMinMax(data.timeToApply);
        this.participants = this.parseMinMax(data.participants);
        this.disciplines = data.disciplines;
        this.languages = data.languages;
        this.contents = data.contents;
        this.experienceReport = data.experienceReport;
        this.materials = data.materials;
        this.adaptedPlanDescription = data.adaptedPlanDescription;
        this.evaluate = data.evaluate;
        this.advantages = data.advantages;
        this.challenges = data.challenges;
        this.publications = data.publications;
        this.results = data.results;
        this.considerations = data.considerations;
    }

    private parseMinMax(timeToApply: string): MinMax {
        const [min, max] = timeToApply.split('-').map(Number);
        return {
            min,
            max
        };
    }

    static parseOtherValues(data: dataModel, otherValues: otherValues): dataModel {
        if (data.disciplines.includes("others")) {
            data.disciplines = data.disciplines.filter(discipline => discipline !== "others");

            if (otherValues.disciplines) {
                data.disciplines.push(otherValues.disciplines);
            }
        }
        if (data.languages.includes("others")) {
            data.languages = data.languages.filter(language => language !== "others");

            if (otherValues.languages) {
                data.languages.push(otherValues.languages);
            }
        }

        return data;
    }

    static build(data: dataModel, otherValues: otherValues): ExperienceRegisterModel {
        const formattedData = this.parseOtherValues(data, otherValues);
        return new ExperienceRegisterModel(formattedData, otherValues);
    }
}