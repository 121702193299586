import React from "react";
import { styled } from "styled-components";
import { DeleteButton, EditButton } from "../shared/button/button.styled";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          <p>Deseja realmente excluir?</p>
        </ModalContent>
        <ModalFooter>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          <ConfirmButton onClick={onConfirm}>Confirmar</ConfirmButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 4;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 15px;
  width: 85%;
  max-width: 534px;
`;

const ModalContent = styled.div`
  padding: 30px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;

  @media (max-width: 540px) {
    justify-content: center;
  }
`;

const CancelButton = styled(DeleteButton)`
  margin-right: 10px;
`;

const ConfirmButton = styled(EditButton)``;

export default ConfirmationModal;
