import { Container } from "UI/components/shared/pages-styles/pages-styles";
import { Box, Typography } from "@mui/material";
import React from "react";
import { emconstrucao } from "assets/index";

const Recommendations: React.FC = () => {

    return (
        <Container>
            <Box alignItems="center" display="flex" flexDirection="column">
                <Typography my={2} variant="h4" sx={{ textAlign: "center" }}>Em Construção</Typography>
                <img src={emconstrucao} alt={emconstrucao} style={{ maxWidth: "70%", alignSelf: "center" }} />
            </Box>
        </Container>
    )
}

export default Recommendations;