import { getUser } from "api";
import { useToken, useUserMutations } from "hooks";
import { useState } from "react";
import { useQuery } from "react-query";
import { styled } from "styled-components";
import { DeleteButton, EditButton } from "../shared/button/button.styled";
import CustomInput from "../shared/custom-input/custom-input.component";
import { FormContainer } from "../shared/custom-input/custom-input.styled";
import ReactLoading from "react-loading";

const initialErrors = {
  id: false,
  username: false,
  checked: false,
  completed: false,
  createdAt: false,
  createdBy: false,
  deleted: false,
  email: false,
  firstName: false,
  picture: false,
  role: false,
  updatedAt: false,
  updatedBy: false,
  __v: false,
  _id: false,
};

interface User {
  id: number;
  username: string;
  checked: boolean;
  completed: boolean;
  createdAt: string;
  createdBy: string;
  deleted: boolean;
  email: string;
  firstName: string;
  picture: string;
  role: string;
  updatedAt: string;
  updatedBy: string;
  __v: number;
  _id: string;
}

const UserEditModalForm: React.FC<{
  item: User;
  handleUserEdit: () => void;
  type: string;
}> = ({ item, handleUserEdit }) => {
  const [userInfo, setUserInfo] = useState<User>(item);
  const [errorsData, setErrorsData] = useState(initialErrors);
  const { updateUserMutation } = useUserMutations();
  const [isLoadinTrue, setIsLoadingTrue] = useState(false);

  const token = useToken();

  const { data, error, isLoading } = useQuery("user", () =>
    getUser(item._id, token)
  );

  if (isLoading) {
    return (
      <ModalOverlay>
        <Modal>
          <RelativeContainer flex={"true"}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#007bff"}
              height={70}
              width={70}
            />
          </RelativeContainer>
        </Modal>
      </ModalOverlay>
    );
  }

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;
    setUserInfo((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const requiredFields = ["username", "role"];
    const hasEmptyFields = requiredFields.filter((field) => {
      setErrorsData((prev) => ({ ...prev, [field]: !data[field] }));
      return !data[field];
    });

    if (hasEmptyFields.length > 0) return;
    setIsLoadingTrue(true);
    try {
      await updateUserMutation.mutateAsync({
        userId: item._id,
        token,
        data: userInfo,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTrue(false);

      handleUserEdit();
    }
  };
  return (
    <ModalOverlay>
      <Modal>
        <RelativeContainer flex={""}>
          <FormContainerColumn onSubmit={handleSubmit}>
            <CustomInput
              inputType="input"
              id="username"
              height="30px"
              label="Nome"
              type="text"
              flex="1 0 40%"
              value={userInfo["username"]}
              onChange={handleChange}
              required
              errorMessage={errorsData["username"] ? "Campo obrigatório" : ""}
            />
            <CustomLabel htmlFor="role">Role/papel:</CustomLabel>
            <CustomSelect
              id="role"
              name="role"
              value={userInfo["role"]}
              onChange={handleChange}
              height="35px"
            >
              <option value="">Selecione...</option>
              <option value="Admin">Admin</option>
              <option value="Common">Common</option>
              <option value="Teacher">Teacher</option>
            </CustomSelect>
            {errorsData["role"] && (
              <ErrorMessage>Campo obrigatório</ErrorMessage>
            )}
            <DeleteButtonRelative onClick={() => handleUserEdit()}>
              Cancelar
            </DeleteButtonRelative>
            <EditButtonRelative type="submit">
              {isLoadinTrue && (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fff"}
                  height={15}
                  width={15}
                />
              )}
              {!isLoadinTrue && "Salvar"}
            </EditButtonRelative>
          </FormContainerColumn>
        </RelativeContainer>
      </Modal>
    </ModalOverlay>
  );
};

const FormContainerColumn = styled(FormContainer)`
  flex-direction: column;
`;

const RelativeContainer = styled.div<{ flex }>`
  position: relative;
  ${({ flex }) =>
    flex?.length
      ? "display:flex; align-items: center; justify-content: center;"
      : ""}
  width: 100%;
  height: 100%;
`;

const DeleteButtonRelative = styled(DeleteButton)`
  position: absolute;
  bottom: 0;
  right: 80px;
`;

const EditButtonRelative = styled(EditButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
const ErrorMessage = styled.span`
  color: red;
  font-size: 20px !important;
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 10px;
  text-align: start;
`;

const CustomLabel = styled.label`
  pointer-events: none;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  @media (max-width: 1240px) {
    font-size: 14px;
  }
`;

const CustomSelect = styled.select<{ height }>`
  height: ${({ height }) => height};
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-left: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #fff;
`;
const Modal = styled.div`
  position: fixed;
  z-index: 5;
  width: 85%;
  max-width: 534px;
  height: 50%;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  padding: 30px;
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;
export default UserEditModalForm;
