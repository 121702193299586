import React from 'react';
import { ClipLoader } from 'react-spinners';
import { styled , keyframes, css } from 'styled-components';

interface LoadingProps {
    size: number;
    color: string;
}

const Loading: React.FC<LoadingProps> = ({ size, color }) => {
    return (
        <Box>
            <WavingText >Loading...</WavingText>
            <ClipLoader size={size} color={color} />
        </Box>
    );
};

const changeColor = keyframes`
  0% { color: red; }
  50% { color: blue; }
  100% { color: green; }
`;

interface ChangingTextProps {
  animate?: boolean;
}

const ChangingText = styled.p<ChangingTextProps>`
  ${({ animate }) => animate && css`
    animation: ${changeColor} 3s infinite;
  `}
`;const waveAnimation = keyframes`
0% {
  transform: rotate(0);
  color: red;
}
25% {
  transform: rotate(-10deg);
  color: blue;
}
50% {
  transform: rotate(10deg);
  color: green;
}
75% {
  transform: rotate(-10deg);
  color: orange;
}
100% {
  transform: rotate(0);
  color: purple;
}
`;

const WavingText = styled.p`
display: inline-block;
animation: ${waveAnimation} 2s infinite;
`;


const Box = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  p {
    font-size: 22px;
  }
`;

export default Loading;
