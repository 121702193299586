import { getMethodologies, getUsers } from "api";
import React from "react";
import { styled } from "styled-components";
import { LoadingDashBoard } from "UI/components";
import { useQuery } from "react-query";
import { useUserMutations } from "hooks/use-user-mutation";
import { useMethodologiesMutations, useToken } from "hooks";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import TableMethodology from "UI/components/table-methodologies/table-methodologies.component";
import { useUserRepository } from "repositories";

interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

const MethodologyManager: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const { deleteMethodologyMutation } = useMethodologiesMutations();
  const { user } = useUserRepository();
  const token = user?.accessToken;

  const { data, error, isLoading } = useQuery<Methodology[], AxiosError>(
    "methodologies",
    () => getMethodologies(token)
  );

  // Verificar se o caminho atual corresponde a '/admin-dashboard/methodologies-manager/edit-methodology/:id'
  const isEditMethodology = pathname.includes(
    "/admin-dashboard/methodologies-manager/edit-methodology/"
  );

  if (error?.response?.status) {
    toast.error(
      "Deu ruim no servidor ou você está usando um com o token inválido, tente refazer o login",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    console.error(error);
    localStorage.clear();

    return <Navigate to="/" />;
  }

  const handleDeleteUser = (methodologyIdToDelete: string) => {
    deleteMethodologyMutation.mutate({
      methodologyId: methodologyIdToDelete,
      token,
    });
  };

  const headers = ["Válido", "Nome", "Ver", "Ações"];

  if (isLoading) {
    return <LoadingDashBoard />;
  }

  return (
    <PageContainer>
      {isEditMethodology ? (
        <Outlet />
      ) : (
        <TableMethodology
          data={data}
          headers={headers}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onEdit={() => {}}
          onDelete={handleDeleteUser}
          renderConfig={{
            fields: ["checked", "role", "username", "email"],
          }}
        />
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  margin: 30px 0px;
`;

export default MethodologyManager;
