import { api, setAuthHeader } from "api/api";


async function getContentType(type: string, token: string): Promise<any> {
  const response = await api.get(`content/type/${type}`, await setAuthHeader(token));
  console.log(response.data);
  return response.data.list;
}

export {
  getContentType
};
