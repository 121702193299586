import { IoMenu } from "react-icons/io5";
import styled from "styled-components";

interface TopBarProps {
  title: string;
  toggleSidebar: () => void;
  toggleFixedSidebar: () => void;
}
const TopBar: React.FC<TopBarProps> = ({ title, toggleSidebar }) => {
  const titlesMap = {
    "/admin-dashboard/graphics": "Gráficos",
    "/admin-dashboard/users-manager": "Gerenciador de Usuários",
    "/admin-dashboard/methodologies-manager": "Gerenciador de Metodologias",
  };

  return (
    <TopBarWrapper>
      <IconMenu onClick={() => toggleSidebar()} />
      <Title>{titlesMap[title]}</Title>
    </TopBarWrapper>
  );
};

export default TopBar;

const IconMenu = styled(IoMenu)`
  display: none;
  font-size: 35px;
`;
const TopBarWrapper = styled.div`
width: 100%;
  display: flex;
  align-items: center;
  border: 0;
  font-size: 16px;
  border-radius: 0;
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const Title = styled.h2`
  font-weight: 400;
  margin: 5px 0;
  font-size: 20px;
  color: #888;
`;
