// useUserMutations.js
import {
  getMethodologies,
  deleteMethodology,
  updateMethodology,
  getMethodology,
} from "api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

interface UseMethodologyMutations {
  //   checkMethodologyMutation: ReturnType<typeof useMutation>;
  deleteMethodologyMutation: ReturnType<typeof useMutation>;
  updateMethodologyMutation: ReturnType<typeof useMutation>;
  getMethodologyMutation: ReturnType<typeof useMutation>;
}

export interface Methodology {
  name: string;
  about: string;
  checked: boolean;
  timeToApply: {
    min: number;
    max: number;
    _id: string;
  };
  participants: {
    min: number;
    max: number;
    _id: string;
  };
  roles: string[];
  subjects: string;
  contents: string;
  programmingLanguages: string;
  materials: string;
  principles: string;
  howToPlan: string;
  planningTime: string;
  aboutSteps: string;
  stepsNumber: number;
  steps: {
    name: string;
    description: string;
  }[];
  howToEvaluate: string;
  observedMetrics: string;
  advantages: string;
  challenges: string;
  considerations: string;
  experiences: {
    article: string;
    link: string;
  }[];
  references: string[];
  image: string;
  _id?: string;
}

export const useMethodologiesMutations = (): UseMethodologyMutations => {
  const queryClient = useQueryClient();

  const updateMethodologyMutation = useMutation<
    void,
    any,
    { methodologyId: string; token: string; data: Methodology }
  >(
    async ({ methodologyId, token, data }) => {
      await updateMethodology(methodologyId, token, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("methodologies");
        toast("Metodologia editada com sucesso!");
      },
    }
  );

  const getMethodologyMutation = useMutation<
    void,
    any,
    { methodologyId: string; token: string }
  >(
    async ({ methodologyId, token }) => {
      await getMethodology(methodologyId, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("methodologies");
        toast("Metodologia buscada com sucesso!");
      },
    }
  );

  const deleteMethodologyMutation = useMutation<
    void,
    any,
    { methodologyId: string; token: string }
  >(
    async ({ methodologyId, token }) => {
      await deleteMethodology(methodologyId, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("methodologies");
        toast("Methodologia excluída com sucesso!");
      },
    }
  );

  return {
    deleteMethodologyMutation,
    updateMethodologyMutation,
    getMethodologyMutation,
  };
};

export default useMethodologiesMutations;
