import { grey, root } from "UI/theme/colors/colors";
import { methodologyDefault } from "assets/index";
//import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { styled } from "styled-components";

interface MethodologieCardProps {
  bgcolor?: string;
  name?: string;
  index?: number;
  children?: ReactNode;
  about: string;
  image?: string;
  onClick?: () => void;
}

type CardProps = {
  bgcolor?: string;
  maxWidth?: any;
};

const MethodologieCard: React.FC<MethodologieCardProps> = ({
  bgcolor,
  name,
  about,
  image,
  children,
  onClick,
  index,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 1240;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
      <Card onClick={onClick} bgcolor={bgcolor} maxWidth={windowWidth}>
        <img
          src={image ?? methodologyDefault}
          alt={`Imagem ilustrativa da metodologia ${name}`}
        />
        <Box >
          {name && (
            <TopCard>
              <h4>{name}</h4>
            </TopCard>
          )}
          <p>{about}</p>
          {children}
        </Box>
      </Card>

  );
};

const Card = styled.div<CardProps>`
  background-color: ${({ bgcolor }) => bgcolor ?? grey[50]};
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 24px;
  border-radius: 8px;
  overflow: auto;
  margin-bottom: 16px;
  img {
    height: 208px;
    width: 210px;
    margin-right: 24px;
    background-color: ${root[0]};
    border-radius: 8px;
  }

  @media (max-width: 1240px) {
    //width: ${({ maxWidth }) => `${maxWidth}px`};;
    //flex-direction: column;
    padding: 8px;
    img {
      height: 88px;
      width: 110px;
      margin-right: 8px;
      //display: none;
    }
  }
`;

const Box = styled.div`
  min-width: 825px;
  p {
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    max-height: 95px;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 1240px) {
    min-width: auto;
    p {
      font-size: 10px;
      line-height: 14px;
      max-width: 100%;
      max-height: 55px;
    }
  }
`;

const TopCard = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  h4 {
    font-family: "Inter";
    font-weight: 800;
    font-size: 24px;
    text-align: left;
    width: 100%;
  }
  h5 {
    font-family: "Inter";
    font-size: 18px;
    margin-right: 10px;
  }
  div {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1240px) {
    align-items: flex-start;
    width: auto;
    min-width: auto;
    justify-content: flex-start;
    h4 {
      font-size: 10px;
      width: 50%;
    }
  }
`;

export default MethodologieCard;
