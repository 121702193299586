import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Label } from 'UI/pages/experience-form/experience-form.styled';

interface CustomInputAutoCompleteProps {
    description: string;
    handleValues: (values: any) => void;
    optionsValues: {
        label: string;
        value: string;
    }[];
    title?: string;
    acceptsMultipleChoices?: boolean;
    disabledCloseOnSelect?: boolean;
}

export const CustomInputAutoComplete: React.FC<CustomInputAutoCompleteProps> = ({ title, disabledCloseOnSelect, handleValues, description, optionsValues, acceptsMultipleChoices }): any => {
    const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
    
    const handleChange = (_, selectedValues, actionType, selectedValue) => {
        const hasSelectedValues = selectedValues.length > 0;
        if(!acceptsMultipleChoices) {
            let clearValues = [selectedValue?.option];
            if(actionType !== 'selectOption') { 
                clearValues = [];
            }
            const handleValue = hasSelectedValues ? selectedValue.option.value : '';
            setSelectedOptions(clearValues)
            handleValues(handleValue);
            return;
        }

        let clearValues = selectedValues;
        for (let i = 0; i < selectedOptions.length; i++) {
            let equals = 0;
            for (let j = 0; j < selectedValues.length; j++) {
                if (selectedOptions[i].value === selectedValues[j].value) {
                    equals++;
                    if (equals > 1) {
                        clearValues = selectedValues.filter((item) => item.value !== selectedOptions[i].value);
                    }
                }
            }
        }

        handleValues(selectedValue?.option?.value ?? actionType);
        setSelectedOptions(clearValues);
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon
        fontSize="small"
        color="primary"
    />;

    return (
        <Box sx={{ marginBottom: '24px'}}>
            {title && <Label>{title}</Label>}
            <Autocomplete
                multiple
                id="auto-complete"
                options={optionsValues}
                disableCloseOnSelect={!disabledCloseOnSelect}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, _, { value }) => {
                    return (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={value?.some((val) => val.value === option.value)}
                            />
                            {option.label}
                        </li>)
                }}
                value={selectedOptions}
                onChange={handleChange}
                style={{ width: '450px' }}
                renderInput={(params) => (<TextField {...params} placeholder={!selectedOptions.length ? description : ''} />)}
            />
        </Box>

    );
}

export default CustomInputAutoComplete;