import React, { useState } from "react";
import Button from "../shared/button/button.component";
import CustomInput from "../shared/custom-input/custom-input.component";
import { CustomSeparator } from "../shared/separator/separator.styled";
import { Box, Container } from "./contact-form.styled";
import { FormContainer } from "../shared/custom-input/custom-input.styled";

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
};

const initialErrors = {
  firstName: false,
  lastName: false,
  email: false,
  message: false,
};

const ContactForm: React.FC = () => {
  const [data, setData] = useState(initialData);
  const [errorsData, setErrorsData] = useState(initialErrors);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    setData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const requiredFields = ["firstName", "email", "message"];
    const hasEmptyFields = requiredFields.filter((field) => {
      setErrorsData((prev) => ({ ...prev, [field]: !data[field] }));
      return !data[field];
    });

    if (hasEmptyFields.length > 0) return;
    /*
     *Todo
     *Enviar dados para o backend
     */
  };

  return (
    <Container>
      <h3>Nos envie uma mensagem</h3>
      <CustomSeparator width="100%" height="1px" bgcolor="#c4c4c4" />
      <Box>
        <FormContainer onSubmit={handleSubmit}>
          <CustomInput
            inputType="input"
            id="firstName"
            height="30px"
            label="Primeiro nome"
            type="text"
            flex="1 0 40%"
            value={data["firstName"]}
            onChange={handleChange}
            required
            errorMessage={errorsData["firstName"] ? "Campo obrigatório" : ""}
          />
          <CustomInput
            inputType="input"
            id="lastName"
            height="30px"
            label="Último nome"
            type="text"
            flex="1 0 40%"
            value={data["lastName"]}
            onChange={handleChange}
          />
          <CustomInput
            inputType="input"
            id="email"
            height="30px"
            label="Email"
            type="text"
            flex="1 0 100%"
            value={data["email"]}
            onChange={handleChange}
            required
            errorMessage={errorsData["email"] ? "Campo obrigatório" : ""}
          />
          <CustomInput
            inputType="textarea"
            id="message"
            height="335px"
            label="Escreva aqui sua mensagem"
            flex="1 0 100%"
            value={data["message"]}
            onChange={handleChange}
            required
            errorMessage={errorsData["message"] ? "Campo obrigatório" : ""}
          />
          <Button
            color="#ffffff"
            width="613px"
            height="55px"
            radius="16px"
            bgcolor="#308099"
            type="submit"
          >
            Enviar
          </Button>
        </FormContainer>
      </Box>
    </Container>
  );
};

export default ContactForm;
