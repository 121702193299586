const blue = {
    50: '#AEDFF7',
    100: '#667a9b'
}

const green = {
    50: '#B8E0C9',
    100: '#04CDAE'
}

const grey = {
    50: '#F5F5F5',
    100: '#333',
    150: '#ececec'
}

const red = {
    50:'#D94022',
    100: '#FF5733'
}

const root = {
    0: '#fff',
    900: '#000'
}


export {
    blue,
    green,
    grey,
    red,
    root
}