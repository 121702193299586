import { Button, Carousel, Section } from "UI/components";
import React from "react";
import { Divider, Box as MuiBox, Typography } from "@mui/material";
import { Box } from "./home.styled";
import {
  porqueadotar,
  respostama,
  perguntaexperiencia,
  feedback,
  porqueadotar2,
} from "assets";
import { oCollabbProg2 } from "assets/index";
import {
  home1
} from "assets";
import { Container } from "UI/components/shared/pages-styles/pages-styles";
import { blue, grey, root } from "UI/theme/colors/colors";

const Home: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState({
    whatImplements: true,
    about: false,
  });

  return (
    <Container>
      <MuiBox
        width='100%'
        bgcolor={root[0]}
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
        pt={4}
        pb={8}
      >
        <MuiBox
          width='100%'
          display='flex'
          justifyContent='center'
          sx={{
            animation: 'up-down 3s ease-in-out infinite alternate-reverse both',
            '@keyframes up-down': {
              '0%': {
                transform: 'translateY(0)',
              },
              '100%': {
                transform: 'translateY(10px)',
              }
            }
          }}
        >
          <img width='850px' src={oCollabbProg2} alt={oCollabbProg2} />
        </MuiBox>
        <Typography maxWidth='80%' textAlign='justify' variant='h5'>O CollabProg é uma plataforma web colaborativa e aberta que reúne diversos tipos de metodologias ativas para o ensino de programação em um único ambiente.</Typography>
      </MuiBox>

      <MuiBox
        width='100%'
        bgcolor={root[0]}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='justify'
        pt={4}
        pb={8}
        borderTop={`2px solid ${grey[150]}`}
      >
        <MuiBox display='flex' mt={2}>
          <img width='400px' src={porqueadotar2} alt={porqueadotar2} />
          <MuiBox>
            <MuiBox
              display='flex'
              textAlign='left'
              justifyContent="flex-start"
              width='100%'
            >
              <MuiBox mr={2}>
                <Button
                  width="300px"
                  bgcolor={selectedTab.whatImplements ? blue[100] : grey[150]}
                  color={selectedTab.whatImplements ? root[0] : "#2c2c2c"}
                  onclick={() => setSelectedTab({ whatImplements: true, about: false })}
                >
                  Por que adotar metodologias ativas para o ensino de programação?
                </Button>
              </MuiBox>
              <Button
                width="300px"
                bgcolor={selectedTab.about ? blue[100] : grey[150]}
                color={selectedTab.about ? root[0] : "#2c2c2c"}
                onclick={() => setSelectedTab({ whatImplements: false, about: true })}
              >
                E ai! Como foi sua experiência com o CollabProg?
              </Button>
            </MuiBox>
            <Divider sx={{ margin: '16px 0' }} flexItem />
            <MuiBox maxWidth='600px'>
              {selectedTab.whatImplements && <Typography mt={2} ml={2} width='auto' variant='body1'>
                As Metodologias Ativas envolvem os estudantes em um aprendizado ativo, promovendo a construção de novos conhecimentos, o pensamento crítico e a reflexão sobre a prática.
              </Typography>}
              {selectedTab.about && <Typography mt={2} ml={2} width='auto' variant='body1'>
                O CollabProg é um ambiente colaborativo e aberto à comunidade acadêmica. Vamos lá, compartilhe suas experiências com a comunidade e auxilie no processo de decidir se devemos ou não adotar uma metodologia específica.
              </Typography>}
            </MuiBox>
          </MuiBox>

        </MuiBox>
      </MuiBox>
    </Container>

  );
};

export default Home;
