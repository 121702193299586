import React, { useState } from "react";
import { styled } from "styled-components";
import UserTableHeader from "./table-header.component";
import TableRow, { RenderConfig } from "./table-row.component";

interface TableProps<T> {
  data: T[];
  headers: string[];
  onEdit: (item: T) => void;
  onDelete: (itemId: string) => void;
  renderConfig: RenderConfig;
}

const Table: React.FC<TableProps<any>> = ({
  data,
  headers,
  onEdit,
  onDelete,
  renderConfig,
}) => {
  return (
    <ContainerMain>
      <TableWrapper>
        <UserTableHeader headers={headers} />
        <tbody>
          {data.length &&
            data.map((rowData) => (
              <TableRow
                key={rowData._id}
                item={rowData}
                onEdit={onEdit}
                onDelete={onDelete}
                renderConfig={renderConfig}
              />
            ))}
        </tbody>
      </TableWrapper>
    </ContainerMain>
  );
};
const ContainerMain = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    color: red;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3498db;
    border-radius: 15px;
    outline: 1px solid slategrey;
    color: red;
  }
`;
const TableWrapper = styled.table`
  tr:nth-child(odd) {
    background-color: #ebf3f9;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 0.5em 1em;
  }

  tr {
    border-color: #bfbfbf;
  }
  width: 100%;
  white-space: nowrap;
`;
export default Table;
