import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 20px !important;
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 10px;
  text-align: start;
`;

export { Container, ErrorMessage };
