import { Step, StepHead } from "UI/components/shared/steps-cards/steps-cards.styled";
import { ReactNode, useState } from "react";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

interface HowImplementProps { data: any }

type HowToImplementData = {
    roles: boolean,
    materials: boolean,
    planningTime: boolean,
    howToEvaluate: boolean,
    howToPlan: boolean,
    aboutSteps: boolean
};

const initialState: HowToImplementData = {
    roles: false,
    materials: false,
    planningTime: false,
    howToEvaluate: false,
    howToPlan: false,
    aboutSteps: false,
};

const getAllSteps = (steps: { name: string, description: string }[]) => {
    const obj = {};
    steps.forEach((_, index) => {
        obj[`step${index + 1}`] = false;
    });
    return obj;
}

const HowImplement: React.FC<HowImplementProps> = ({ data }) => {
    const [dataState, setDataState] = useState<HowToImplementData>(initialState);
    const roles = Object.values(data.roles) as ReactNode[];
    const steps: { name: string, description: string }[] = data.steps;
    const [stepStates, setStepStates] = useState<{ [key: string]: boolean }>(getAllSteps(steps));
    const handleStepClick = (stepKey: string) => {
        setStepStates((prevState) => ({
            ...prevState,
            [stepKey]: !prevState[stepKey],
        }));
    };

    const handleFieldClick = (field: keyof HowToImplementData) => {
        setDataState((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <>
            <Step>
                <StepHead>
                    <h3>Papéis do estudante/docente na metodologia</h3>
                    <div onClick={() => handleFieldClick('roles')}>
                        {dataState.roles ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.roles && roles.map(role => <h4>{role}</h4>)}
            </Step>
            <Step>
                <StepHead>
                    <h3>Materiais/ferramentas a serem utilizadas</h3>
                    <div onClick={() => handleFieldClick('materials')}>
                        {dataState.materials ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.materials && <h4>{data.materials}</h4>}
            </Step>
            <Step>
                <StepHead>
                    <h3>Adoção da metodologia em sala de aula</h3>
                    <div onClick={() => handleFieldClick('howToPlan')}>
                        {dataState.howToPlan ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.howToPlan && <h4>{data.howToPlan}</h4>}
            </Step>
            {data.planningTime.length > 0 && <Step>
                <StepHead>
                    <h3>Tempo médio para o planejamento da aula</h3>
                    <div onClick={() => handleFieldClick('planningTime')}>
                        {dataState.planningTime ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.planningTime && <h4>{data.planningTime}</h4>}
            </Step>}
            <Step>
                <StepHead>
                    <h3>Sobre as Etapas para adoção da metodologia</h3>
                    <div onClick={() => handleFieldClick('aboutSteps')}>
                        {dataState.aboutSteps ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.aboutSteps && <h4>{data.aboutSteps}</h4>}
            </Step>
            {steps.map((step, index) => <Step>
                <StepHead>
                    <h3>{step.name}</h3>
                    <div onClick={() => handleStepClick(`step${index + 1}`)}>
                        {stepStates[`step${index + 1}`] ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {stepStates[`step${index + 1}`] && <h4>{step.description}</h4>}
            </Step>)}
            <Step>
                <StepHead>
                    <h3>Como avaliar a aprendizagem</h3>
                    <div onClick={() => handleFieldClick('howToEvaluate')}>
                        {dataState.howToEvaluate ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </StepHead>
                {dataState.howToEvaluate && <h4>{data.howToEvaluate}</h4>}
            </Step>
        </>

    )
}

export default HowImplement;    