import styled from "styled-components";
import { SeparatorProps } from "./separator.types";

const CustomSeparator = styled.div<SeparatorProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgcolor }) => bgcolor};
  margin-left: ${({ marginleft }) => (marginleft ? marginleft : "initial")};
  margin-right: ${({ marginleft }) => (marginleft ? marginleft : "initial")};
  @media (max-width: 1240px) {
        flex-direction: row;
        width: ${({ height }) => height};
        height: ${({ width }) => width};
        background-color: ${({ bgcolor }) => bgcolor};
    }
`;

export { CustomSeparator };
