import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useUserMutations } from "hooks/use-user-mutation";
import { useToken } from "hooks";
import ReactLoading from "react-loading";
import UserEditModalForm from "../user-edit-modal-form/user-edit-modal-form.component";
import { DeleteButton, EditButton } from "../shared/button/button.styled";
import UserViewModalForm from "../user-view/user-view.component";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";

interface User {
  id: number;
  username: string;
  checked: boolean;
  completed: boolean;
  email: string;
  firstName: string;
  picture: string;
  role: string;
  _id: string;
  createdBy: string;
  updatedBy: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

type ItemProps = User;

interface TableActions<T> {
  onEdit: (item: T) => void;
  onDelete: (itemId: string) => void;
}

export interface RenderConfig {
  fields: string[] | Record<string, any>;
}

interface TableRowProps<T> extends TableActions<T> {
  item: T;
  renderConfig: RenderConfig;
}

const TableRow: React.FC<TableRowProps<ItemProps>> = ({
  item,
  onEdit,
  onDelete,
  renderConfig,
}) => {
  const { checkUserMutation } = useUserMutations();
  const [isLoading, setIsLoading] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [userView, setUserView] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const token = useToken();

  const handleValidateUser = async () => {
    setIsLoading(true);
    try {
      await checkUserMutation.mutateAsync({
        userId: item._id,
        token,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserEdit = () => {
    setUserEdit(!userEdit);
  };

  const handleUserView = () => {
    setUserView(!userView);
  };

  const handleDeleteUser = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setConfirmationModalOpen(false);
    onDelete(item._id);
  };

  const handleCancelDelete = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <>
      {userEdit && (
        <UserEditModalForm
          item={item as User}
          handleUserEdit={handleUserEdit}
          type={"users"}
        />
      )}
      {userView && (
        <UserViewModalForm
          item={item as User}
          handleUserView={handleUserView}
          type={"users"}
        />
      )}
      {isLoading ? (
        <tr>
          <td colSpan={6}>
            <div style={{ textAlign: "center" }}>
              <ReactLoading
                type={"spinningBubbles"}
                color={"#007bff"}
                height={30}
                width={30}
              />
            </div>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <input
              type="checkbox"
              checked={item.checked}
              onChange={handleValidateUser}
            />
          </td>
          <td>{(item as User).role}</td>
          <td>{(item as User).username}</td>
          <td>{(item as User).email}</td>
          <td>
            <AiFillEye
              onClick={() => handleUserView()}
              fontSize={30}
              cursor={"pointer"}
            />
          </td>
          <td
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <EditButton onClick={() => handleUserEdit()}>Editar</EditButton>
            <DeleteButton onClick={handleDeleteUser}>Excluir</DeleteButton>
          </td>
        </tr>
      )}

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default TableRow;
