import {
    Box,
    Divider,
    SwipeableDrawer,
    ToggleButton,
    Typography
} from '@mui/material';
import { FiAlignJustify } from "react-icons/fi";
import React, { useState } from "react";
import { useUserRepository } from 'repositories';
import { grey, root } from 'UI/theme/colors/colors';
import { Link } from 'react-router-dom';
import { Img } from './user.profile.styled';
import { PopupLogin } from '../login/login.component';

const UserProfile: React.FC = () => {
    const { user } = useUserRepository();
    const [isDrawerVisible, setDrawerVisibility] = useState(false);

    const signOut = () => {
        window.localStorage.clear();
        window.location.reload();
    }

    const swipeCloseHandler = event => {
        if (!event?.target?.classList.contains('MuiBackdrop-root')) return;
        setDrawerVisibility(false);
    };

    return (
        <>
            <ToggleButton
                onClick={() => setDrawerVisibility(true)}
                selected={isDrawerVisible}
                size="small"
                style={{ border: 'none', padding: 0 }}
                value
            >
                <FiAlignJustify size={35} />
            </ToggleButton>
            {isDrawerVisible && <SwipeableDrawer
                data-testid="user-menu-drawer"
                anchor="bottom"
                disableSwipeToOpen
                onClose={swipeCloseHandler}
                onOpen={() => console.log('noop')}
                open={isDrawerVisible}
                PaperProps={{
                    style: {
                        borderTopRightRadius: '12px',
                        borderTopLeftRadius: '12px'
                    }
                }}
            >
                <Box
                    alignSelf="center"
                    borderRadius="100px"
                    height="4px"
                    mt={3}
                    style={{ backgroundColor: grey[100] }}
                    width="40px"
                />
                <Box mt={3} py={2} px={3}>
                    {user?.completed && <>
                        <Box color={root[900]} display="flex" justifyContent='flex-start' alignItems='flex-end'>
                            <Img src={user.picture} />
                            <Box height='50px' style={{ marginLeft: '8px' }}>
                                <Typography variant="subtitle1">{user.firstName}</Typography>
                                <Typography variant="body2">{user.email}</Typography>
                            </Box>
                        </Box>
                        <Box my={3}>
                            <Divider style={{ backgroundColor: grey[50] }} />
                        </Box>
                    </>}
                    {!user?.completed && <>
                        <PopupLogin />
                        <Box my={3}>
                            <Divider style={{ backgroundColor: grey[50] }} />
                        </Box>
                    </>}
                    {user?.role === 'Admin' && <Link to="/admin-dashboard">Graphics</Link>}
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/'>Inicio</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/methodologies'>Metodologias</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/methodology-register'>Cadastrar Metodologia</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/recommendations'>Recomendações</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/about'>Sobre</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    <Link to='/contact'>Contatos</Link>
                    <Box my={3}>
                        <Divider style={{ backgroundColor: grey[50] }} />
                    </Box>
                    {user?.completed && <>
                        <p onClick={signOut}>Logout</p>
                        <Box my={3}>
                            <Divider style={{ backgroundColor: grey[50] }} />
                        </Box>
                    </>}
                </Box>
            </SwipeableDrawer>}

        </>
    )
}

export default UserProfile;