import { ExperienceRegisterModel } from "models";
import { api, setAuthHeader } from "../api";

async function postExperience(token: string, data: ExperienceRegisterModel): Promise<any> {
  const response = await api.post("experience", data, await setAuthHeader(token));
  return response.data;
}

export {
  postExperience
};
