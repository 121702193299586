
import React from "react";
import { Box, Typography } from "@mui/material"
import { IconType } from "react-icons/lib";
import { root } from "UI/theme/colors/colors";

interface SideOptionProps {
    Icon: IconType;
    label: string;
    onClick: () => void;
    isExpanded: boolean;
}

const TextStyles = {
    fontFamily: 'Cormorant Garamond',
    fontSize: '22px',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    marginLeft: '8px',
    color: root[900]
}

export const SideOption: React.FC<SideOptionProps> = ({ Icon, label, onClick, isExpanded }) => {
    return (
        <Box
            display='flex'
            alignItems='center'
            color={root[900]}
            onClick={onClick}
            p={2}
            m={1}
            boxSizing='border-box'
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                    borderRadius: '8px',
                },
                zIndex: 10,
            }}
        >
            <Icon size={32} color={root[900]}/>
            {isExpanded && <Typography sx={{ ...TextStyles, transition: 'opacity 0.2s ease-in-out' }} variant='h6'>{label}</Typography>}
        </Box>
    );

}