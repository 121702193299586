import ReactDOM from 'react-dom/client';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './UI/App';
import { ExperienceRepositoryProvider, FeedbackRepositoryProvider, MethodologyRepositoryProvider, UserRepositoryProvider } from 'repositories';
import { LoginPopupProvider } from 'contexts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <UserRepositoryProvider>
    <LoginPopupProvider>
      <MethodologyRepositoryProvider>
        <FeedbackRepositoryProvider>
          <ExperienceRepositoryProvider>
            <App />
          </ExperienceRepositoryProvider>
        </FeedbackRepositoryProvider>
      </MethodologyRepositoryProvider>
    </LoginPopupProvider>
  </UserRepositoryProvider>
);
