import styled from "styled-components";

const Box = styled.div`
  background-color: rgba(163, 134, 203, 0.2);
  max-width: 1176px;
  display: flex;
  box-sizing: border-box;
  padding: 0 8px;
  flex-direction: column;
  width: 70%;
  margin: 0px auto;

  h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 50px;
    text-align: center;
  }
  h2{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin: 5px 0;
  }
  p {
      margin: 20px 30px;
      font-size: 25px;
      font-family: Cormorant Garamond;
    }
  button {
      margin: 50px 10px;
      &:hover {
        background-color: #64A2A4;;
      }
  }
`;

export {
  Box,
}